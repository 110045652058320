form {
  .form-item-pair {
    display: flex;
    margin: 0 -15px;
    flex-wrap: nowrap;
    padding-top: 30px;

    &:first-of-type {
      padding-top: 0;
    }
  }
  .form-col{
    padding-left: 15px;
    padding-right: 15px;
    @include tablet{
      padding-left: 0;
      padding-right: 0;
    }
  }
  .form-item {
    max-width: 100%;
    width: 100%;

    .form-actions {
      input[type=submit] {
      }
    }
  }

  label {
    color: rgba($color-gray-3, 0.6);
    flex: 0 0 100%;
    padding-left: 18px;


  }

  .form-item {
    //padding-left: 15px;
    //padding-right: 15px;
    @include tablet{
      max-width: 340px;
      margin: 0 auto;
    }
    @include phone{
      max-width: 352px;
    }
    &-input{
      position: relative;
      padding-bottom: 30px;
      &:before{
        content: '';
        display: block;
        position: absolute;
        background-color: $color-yellow;
        box-sizing: border-box;
        left: 0;
        top: 0;
        width: 1px;
        height: 48px;
        transition: all 0.3s ease-in-out
      }
      &.error{
        &:before{
          content: '';
          display: block;
          position: absolute;
          background-color: $color-red;
          box-sizing: border-box;
          left: 0;
          top: 0;
          width: 1px;
          height: 48px;
          transition: all 0.3s ease-in-out
        }

        & .error-message{
          opacity: 1;
        }
      }

    }
    .error-message{
      position: absolute;
      left: 0;
      color: $color-red;
      line-height: 14px;
      bottom: 8px;
      font-size: 1.4rem;
      opacity: 0;
      transition: all 0.3s ease-in-out
    }
    & > label {
      opacity: 0;
    }

    &.focus {
      box-shadow: 0 0 0 4px $color-yellow;
      filter: drop-shadow(0px 0px 4px $color-yellow);
      & > label {
        opacity: 1;
      }

    }

  }

  //input[type="text"],
  //input[type="password"] {
  //  padding: 10px 15px;
  //  color: $main-color;
  //  background: #f5f1f1;
  //  outline: none;
  //  transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  //}

  input[type=submit] {
    padding: 10px;
    font-size: 1.2rem;
    border: 1px solid $color-yellow;
    background-color:  $color-yellow;
    color: $color-white;
    width: 100%;
    text-transform: uppercase;
    transition : all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    border-radius: 0;
  }

  input[type="number"],
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  textarea {
    border-radius: 0;
    flex: 0 0 100%;
    width: 100%;
    font-size: 1.8rem;
    height: 48px;
    font-style: normal;
    font-stretch: normal;
    color: $color-gray-3;
    padding: 18px 20px;
    border: 0 none;
    outline: 1px solid rgba($color-white, 0.15);
    background-color: rgba($color-white, 0);
    transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    &::-webkit-input-placeholder {
      color: $color-gray-3;
      opacity: 0.6;
      font-size: 1.8rem;
      letter-spacing: 0.1em;
    }

    &::-moz-placeholder {
      color: $color-gray-3;
      opacity: 0.6;
      font-size: 1.8rem;
      letter-spacing: 0.1em;
    }

    &:-moz-placeholder {
      color: $color-gray-3;
      opacity: 0.6;
      font-size: 1.8rem;
      letter-spacing: 0.1em;
    }

    &:-ms-input-placeholder {
      color: $color-gray-3;
      opacity: 0.6;
      font-size: 1.8rem;
      letter-spacing: 0.1em;
    }

    &:focus {
      outline: 1px solid rgba($color-yellow, 1);

      &::-webkit-input-placeholder {
        color: transparent;
      }

      &::-moz-placeholder {
        color: transparent;
      }

      &:-moz-placeholder {
        color: transparent;
      }

      &:-ms-input-placeholder {
        color: transparent;
      }
    }

    &:hover {
      outline: 1px solid rgba($color-yellow, 1);
    }
  }

}
