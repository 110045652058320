/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-active {
    border: none;
}

.slick-dotted {
    &.slick-slider {
        margin-bottom: 30px;
        //padding-bottom: 30px;
    }
}

.slick-dots {
    position: absolute;
    bottom: -28px;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    li {
        width: 16px;
        height: 8px;
        //margin: 0 4px;
        padding: 0 4px;
        button {
            border: 0 none;
            cursor: pointer;
            text-indent: -9999px;
            width: 8px;
            height: 8px;
            padding: 0;
            border-radius: 50%;
            background-color: rgba($color-white, 0.15);

            &:before {
                display: none;
            }

            &:hover, &:active {
                background-color: rgba($color-yellow, 1);
            }
        }

        &.slick-active button {
            background-color: rgba($color-yellow, 1);
            &:before {

            }
        }
    }
}


.slick-track {

}

.slick-arrow {
    position: absolute;
    top: 50%;
    left: auto;
    right: -40px;
    z-index: 600;
    width: 10px;
    height: 15px;
    border: 0 none;
    cursor: pointer;
    background-color: transparent;
    padding: 0;

    &.slick-prev {
        right: auto;
        left: -40px;
        top: 50%;
        width: 10px;
        height: 15px;
    }
}

.slick-prev:before, .slick-next:before {
    display: block;
    opacity: 1;
}

.slick-arrow {
    font-size: 0;

    &.slick-next {
        position: absolute;
        width: 100px;
        height: 100%;
        z-index: 210;
        cursor: pointer;
        -webkit-transition: all .15s ease-out;
        transition: all .15s ease-out;
        -webkit-user-select: none;
        -moz-user-select: -moz- none;
        -o-user-select: none;
        -ms-user-select: none;
        user-select: none;
        right: -100px;
        top: 0;
      @include desktop-block{
        right: 0;
      }

        &:before {
            content: '';
            position: absolute;
            top: 45%;
            width: 2rem;
            height: 4rem;
            right: 1.5625rem;
            //background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSLQodC70L7QuV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNyIgdmlld0JveD0iLTEuMzMzIDIzLjEyNSAxMCAxNyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPjwvc3R5bGU+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0tLjMzMyAyMy42NjdsOCA4LTggOCIvPjwvc3ZnPg==") no-repeat 50%;
            background: url('/images/svg/arrow-next.svg') no-repeat 50%;
            background-size: cover;
            transition: all .15s ease-out;
            opacity: 0.15;
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }

    &.slick-prev {
        position: absolute;
        width: 100px;
        height: 100%;
        z-index: 210;
        cursor: pointer;
        -webkit-transition: all .15s ease-out;
        transition: all .15s ease-out;
        -webkit-user-select: none;
        -moz-user-select: -moz- none;
        -o-user-select: none;
        -ms-user-select: none;
        user-select: none;
        left: -100px;
        top: 0;
        @include desktop-block{
          left: 0;
        }
        &:before {
            content: '';
            position: absolute;
            top: 45%;
            width: 2rem;
            height: 4rem;
            left: 1.5625rem;
            //background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSLQodC70L7QuV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjAiIHk9IjAiIHdpZHRoPSIxMCIgaGVpZ2h0PSIxNyIgdmlld0JveD0iLTEyIDIzLjEyNSAxMCAxNyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlPjwvc3R5bGU+PHBhdGggZmlsbD0ibm9uZSIgc3Ryb2tlPSIjMjIyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0tMyAzOS42NjdsLTgtOCA4LTgiLz48L3N2Zz4=") no-repeat 50%;
            background: url('/images/svg/arrow-prev.svg') no-repeat 50%;
            background-size: cover;
            -webkit-transition: all .15s ease-out;
            transition: all .15s ease-out;
            opacity: 0.15;
        }

        &:hover {


            &:before {
                opacity: 1;
            }
        }
    }
}

.slide-hidden {
    display: none;
}
