.p-contact {}

.contact {
    padding-top: 30px;
    &-map {
        position: relative;
        .map {
            &-block {
                width: 100%;
                height: 414px;
                background-color: grey;
            }
            &-info {
                position: absolute;
                top: 50px;
                //width: 100%;
                //height: 100%;
                z-index: 10;
                left: 0;
                //top: 0;
                height: 0;
                width: 100%;
                @include phone {
                    height: 100%;
                    position: relative;
                    left: 0;
                    top: 0;
                    background-color: $color-white;
                }
                &-wrap {
                    max-width: 1140px;
                    height: 0;
                    margin: 0 auto;
                    @include phone {
                        height: 100%;
                    }
                    //display: flex;
                    //justify-content: flex-start;
                    //align-items: center;
                    //@include phone{
                    //  background-color: $color-white;
                    //}
                }
                &-block {
                    max-width: 375px;
                    width: 100%;
                }
                &-row {
                    display: flex;
                    flex-wrap: nowrap;
                    &:last-of-type {
                        .map-info-right {
                            &:after {
                                display: none;
                            }
                        }
                    }
                }
                &-left {
                    flex: 0 0 84px;
                    background-color: $color-yellow;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 104px;
                }
                &-right {
                    height: 104px;
                    padding-left: 30px;
                    flex: 1;
                    background-color: $color-white;
                    position: relative;
                    display: flex;
                    align-items: center;
                    @include extra-phone-single {
                        padding-left: 10px;
                    }
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 30px;
                        height: 1px;
                        width: 100px;
                        display: block;
                        background-color: rgba($color-blue-2, 0.1);
                    }
                }
                &-icon {
                    width: 24px;
                    height: 24px;
                    display: block;
                    background-image: url("/images/svg/contact-anchor.svg");
                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center;
                    background-size: contain;
                }
                &-anchor {
                    background-image: url("/images/svg/contact-anchor.svg");
                }
                &-phone {
                    background-image: url("/images/svg/contact-phone.svg");
                }
                &-email {
                    background-image: url("/images/svg/contact-email.svg");
                }
                &-cont {
                    color: $color-blue-2;
                    font-size: 1.4rem;
                    line-height: 25px;
                }
                &-link {
                    color: $color-blue-2;
                    font-size: 1.4rem;
                    display: block;
                    line-height: 25px;
                }
            }
        }
    }
}