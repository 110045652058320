
.p-about{

}
.about{
  padding-top: 30px;
  &-wrapper{

  }
  &-banner{
    height: pxVh(400,1080);
    overflow: hidden;
    .img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-row{
    padding-top: 50px;
    @include portrait-tablet{
      flex-wrap: wrap;
      padding-top: 0;
    }
  }
  &-sidebar{
    max-width: 380px;
    flex: 0 0 33%;
    @include portrait-tablet{
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 0;
      padding-right: 0;
    }
    &-item{

    }
    &-logo{
      border: 1px solid rgba($color-gray-5,0.2);
      position: relative;
      transition: all 0.3s linear;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 240px;
      &:before{
        content: '';
        display: block;
        position: absolute;
        background-color: $color-yellow;
        box-sizing: border-box;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        transition: all 0.3s ease-in-out
      }
      & .img{

      }
    }
  }
  &-content{
    max-width: 760px;
    flex: 0 0 67%;
    @include portrait-tablet{
      max-width: 100%;
      flex: 0 0 100%;
      padding-top: 30px;
      padding-left: 0;
      padding-right: 0;
    }
    &-item{

    }
    &-title{
      &--tile{

      }
    }
    &-desc{


    }
  }
}
