// Responsive breakpoints. "Include media" settings.

$extra-phone: 320px;
$extra-phone-single: 370px;
$extra-phone-big: 375px;
$small-phone: 420px;
$little-phone: 480px;
$big-phone: 577px;
$custom-phone: 600px;
$phone: 640px;
$portrait-tablet: 767px;
$tablet: 768px;
$middle-tablet: 900px;
$menu-tablet: 992px;
$desktop-block: 1024px;
$desktop-wrap: 1200px;
$desktop-little: 1280px;
$desktop-notebook: 1366px;
$desktop-note-big: 1440px;
$desktop: 1600px;
$desktop-normal: 1900px;
$desktop-big: 2560px;
$xxs: 376px;
$xl: 518px;
$xs: 768px;
$sm: 1007px;
$md: 1310px;
$lg: 1550px;

//@mixin extra-phone-big {
//  @media screen and (min-width: $extra-phone-big) {
//    @content;
//  }
//}
//
//@mixin little-phone {
//  @media screen and (min-width: $little-phone) {
//    @content;
//  }
//}
//
//@mixin custom-phone {
//  @media screen and (min-width: $custom-phone) {
//    @content;
//  }
//}
//
//@mixin phone {
//  @media screen and (min-width: $phone) {
//    @content;
//  }
//}
//
//@mixin tablet {
//  @media screen and (min-width: $tablet) {
//    @content;
//  }
//}
//
//@mixin desktop-block {
//  @media screen and (min-width: $desktop-block) {
//    @content;
//  }
//}
//
//@mixin desktop-block-menu {
//  @media screen and (min-width: $desktop-block + 1) {
//    @content;
//  }
//}
//
//@mixin desktop-note-big {
//  @media screen and (min-width: $desktop-note-big) {
//    @content;
//  }
//}
//
//@mixin desktop-normal {
//  @media screen and (min-width: $desktop-normal) {
//    @content;
//  }
//}
//
//@mixin desktop-big {
//  @media screen and (min-width: $desktop-big) {
//    @content;
//  }
//}
@mixin tablet-hover {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}

@mixin desktop-big {
  @media (max-width: #{$desktop-big}) {
    @content;
  }
}
@mixin desktop-normal {
  @media (max-width: #{$desktop-normal}) {
    @content;
  }
}
@mixin desktop-note-big {
  @media (max-width: #{$desktop-note-big}) {
    @content;
  }
}

@mixin desktop-notebook {
  @media (max-width: #{$desktop-notebook}) {
    @content;
  }
}

@mixin desktop-little {
  @media (max-width: #{$desktop-little}) {
    @content;
  }
}
@mixin desktop-wrap {
  @media (max-width: #{$desktop-wrap}) {
    @content;
  }
}
@mixin desktop-block {
  @media (max-width: #{$desktop-block}) {
    @content;
  }
}

@mixin menu-tablet {
  @media (max-width: #{$menu-tablet}) {
    @content;
  }
}

@mixin middle-tablet {
  @media (max-width: #{$middle-tablet}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$tablet}) {
    @content;
  }
}

@mixin portrait-tablet {
  @media screen and (max-width: #{$tablet - 1px}) {
    @content;
  }
}


@mixin phone {
  @media screen and (max-width: #{$phone}) {
    @content;
  }
}

@mixin custom-phone {
  @media screen and (max-width: #{$custom-phone}) {
    @content;
  }
}

@mixin big-phone {
  @media screen and (max-width: #{$big-phone}) {
    @content;
  }
}
@mixin big-phone-xl {
  @media screen and (max-width: #{$xl}) {
    @content;
  }
}

@mixin little-phone {
  @media screen and (max-width: #{$little-phone}) {
    @content;
  }
}

@mixin small-phone {
  @media (max-width: #{$small-phone}) {
    @content;
  }
}

@mixin extra-phone-big {
  @media (max-width: #{$extra-phone-big}) {
    @content;
  }
}
@mixin extra-phone-single {
  @media (max-width: #{$extra-phone-single}) {
    @content;
  }
}
@mixin extra-phone {
  @media (max-width: #{$extra-phone}) {
    @content;
  }
}
