@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/* custom-reset.css | 20.08.2018 */
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus {
  outline: none;
}

.site-width {
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .site-width {
    max-width: 960px;
  }
}
@media screen and (max-width: 640px) {
  .site-width {
    padding-right: 12px;
    padding-left: 12px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
@media screen and (max-width: 768px) {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}

.col {
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .col {
    padding-right: 10px;
    padding-left: 10px;
  }
}

/* Mixin */
*, :after, :before {
  box-sizing: border-box;
}

*:active, *:hover, *:focus {
  outline: 0;
  outline-offset: 0;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 10px;
  color: #000000;
  line-height: 180%;
  min-width: 320px;
}

body {
  margin: 0;
  min-width: 320px;
  overflow-y: scroll;
  font-family: "Roboto", sans-serif;
  font-size: 1.4rem;
  color: #ffffff;
  min-height: 100%;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  background: linear-gradient(267.74deg, #486AA6 0.56%, #1D2F4F 98.79%);
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 1.1;
  color: #ffffff;
  text-transform: uppercase;
}

h2 {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 2.5rem;
}

h3 {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 1.8rem;
}

a {
  transition: all 0.3s linear;
}

p {
  font-size: 1.4rem;
  padding-top: 20px;
  line-height: 25.3px;
  color: #ffffff;
}
p:first-of-type {
  padding-top: 0;
}

.list {
  padding: 20px 0;
}
.list-link {
  display: flex;
  border: 1px solid #7B9BD4;
  border-radius: 30px;
  padding: 20px;
  font-size: 2rem;
  color: #7B9BD4;
  justify-content: center;
}
.list-link:hover {
  background-color: #7B9BD4;
  color: #ffffff;
}

.title-block {
  text-align: center;
}
.title-block .title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 110%;
  color: #ffffff;
}

.site {
  overflow: hidden;
  position: relative;
}

.p-page {
  min-height: 400px;
  padding-top: 220px;
}
@media screen and (max-width: 767px) {
  .p-page {
    min-height: 600px;
    padding-top: 70px;
  }
}

.top-block {
  padding-top: 30px;
}
.top-block .title-block {
  padding-top: 50px;
  text-align: left;
}

.breadcrumbs ul {
  display: flex;
}
.breadcrumbs li {
  padding-right: 25px;
  position: relative;
}
.breadcrumbs li:after {
  position: absolute;
  right: 10px;
  top: 1px;
  content: "";
  display: block;
  background-image: url(/images/svg/arrow-bread.svg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  width: 5px;
  height: 10px;
}
.breadcrumbs li:last-of-type:after {
  display: none;
}
.breadcrumbs a {
  font-size: 1.4rem;
  color: #ffffff;
}
.breadcrumbs a:hover {
  color: #E5891C;
}
.breadcrumbs span {
  font-size: 1.4rem;
  color: #E5891C;
}

.btn {
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  color: #ffffff;
  text-align: center;
  vertical-align: middle;
  font-size: 1.7rem;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid #ffffff;
  max-width: 290px;
  width: 100%;
  text-transform: uppercase;
  height: 50px;
}
.btn:focus {
  text-decoration: none;
}
.btn-white {
  background-color: transparent;
  border: 1px solid #ffffff;
  color: #ffffff;
}
.btn-white:hover {
  background-color: #ffffff;
  color: #009478;
}
.btn-green {
  background-color: transparent;
  border: 1px solid #009478;
  color: #009478;
}
.btn-green:hover {
  background-color: #009478;
  color: #ffffff;
}
.btn-border {
  border: 1px solid #E5891C;
  position: relative;
}
.btn-border:before, .btn-border:after {
  content: "";
  display: block;
  position: absolute;
  border-color: #E5891C;
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  transition: all 0.3s ease-in-out;
}
.btn-border:before {
  left: -6px;
  bottom: -6px;
  border-width: 0 0 1px 1px;
  z-index: 5;
  width: 20%;
}
.btn-border:after {
  top: -6px;
  right: -6px;
  border-width: 1px 1px 0 0;
  width: 50%;
}
.btn-border:hover:before, .btn-border:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: #FF981F;
}
.btn-border:hover {
  color: #ffffff;
  background-color: #FF981F;
  border-color: #FF981F;
}
.btn-yellow {
  border: 1px solid #E5891C;
  position: relative;
  background-color: #E5891C;
}
.btn-yellow:before, .btn-yellow:after {
  content: "";
  display: block;
  position: absolute;
  border-color: #E5891C;
  box-sizing: border-box;
  border-style: solid;
  width: 1em;
  height: 1em;
  transition: all 0.3s ease-in-out;
}
.btn-yellow:before {
  left: -6px;
  bottom: -6px;
  border-width: 0 0 1px 1px;
  z-index: 5;
  width: 20%;
}
.btn-yellow:after {
  top: -6px;
  right: -6px;
  border-width: 1px 1px 0 0;
  width: 50%;
}
.btn-yellow:hover:before, .btn-yellow:hover:after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: #FF981F;
}
.btn-yellow:hover {
  color: #ffffff;
  background-color: #FF981F;
  border-color: #FF981F;
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-active {
  border: none;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -28px;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
}
.slick-dots li {
  width: 16px;
  height: 8px;
  padding: 0 4px;
}
.slick-dots li button {
  border: 0 none;
  cursor: pointer;
  text-indent: -9999px;
  width: 8px;
  height: 8px;
  padding: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
}
.slick-dots li button:before {
  display: none;
}
.slick-dots li button:hover, .slick-dots li button:active {
  background-color: #e5891c;
}
.slick-dots li.slick-active button {
  background-color: #e5891c;
}
.slick-arrow {
  position: absolute;
  top: 50%;
  left: auto;
  right: -40px;
  z-index: 600;
  width: 10px;
  height: 15px;
  border: 0 none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}
.slick-arrow.slick-prev {
  right: auto;
  left: -40px;
  top: 50%;
  width: 10px;
  height: 15px;
}

.slick-prev:before, .slick-next:before {
  display: block;
  opacity: 1;
}

.slick-arrow {
  font-size: 0;
}
.slick-arrow.slick-next {
  position: absolute;
  width: 100px;
  height: 100%;
  z-index: 210;
  cursor: pointer;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  -webkit-user-select: none;
  -moz-user-select: -moz- none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  right: -100px;
  top: 0;
}
@media (max-width: 1024px) {
  .slick-arrow.slick-next {
    right: 0;
  }
}
.slick-arrow.slick-next:before {
  content: "";
  position: absolute;
  top: 45%;
  width: 2rem;
  height: 4rem;
  right: 1.5625rem;
  background: url("/images/svg/arrow-next.svg") no-repeat 50%;
  background-size: cover;
  transition: all 0.15s ease-out;
  opacity: 0.15;
}
.slick-arrow.slick-next:hover:before {
  opacity: 1;
}
.slick-arrow.slick-prev {
  position: absolute;
  width: 100px;
  height: 100%;
  z-index: 210;
  cursor: pointer;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  -webkit-user-select: none;
  -moz-user-select: -moz- none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: -100px;
  top: 0;
}
@media (max-width: 1024px) {
  .slick-arrow.slick-prev {
    left: 0;
  }
}
.slick-arrow.slick-prev:before {
  content: "";
  position: absolute;
  top: 45%;
  width: 2rem;
  height: 4rem;
  left: 1.5625rem;
  background: url("/images/svg/arrow-prev.svg") no-repeat 50%;
  background-size: cover;
  -webkit-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  opacity: 0.15;
}
.slick-arrow.slick-prev:hover:before {
  opacity: 1;
}

.slide-hidden {
  display: none;
}

form .form-item-pair {
  display: flex;
  margin: 0 -15px;
  flex-wrap: nowrap;
  padding-top: 30px;
}
form .form-item-pair:first-of-type {
  padding-top: 0;
}
form .form-col {
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
  form .form-col {
    padding-left: 0;
    padding-right: 0;
  }
}
form .form-item {
  max-width: 100%;
  width: 100%;
}
form label {
  color: rgba(219, 222, 239, 0.6);
  flex: 0 0 100%;
  padding-left: 18px;
}
@media screen and (max-width: 768px) {
  form .form-item {
    max-width: 340px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 640px) {
  form .form-item {
    max-width: 352px;
  }
}
form .form-item-input {
  position: relative;
  padding-bottom: 30px;
}
form .form-item-input:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #E5891C;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 1px;
  height: 48px;
  transition: all 0.3s ease-in-out;
}
form .form-item-input.error:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #FC4040;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 1px;
  height: 48px;
  transition: all 0.3s ease-in-out;
}
form .form-item-input.error .error-message {
  opacity: 1;
}
form .form-item .error-message {
  position: absolute;
  left: 0;
  color: #FC4040;
  line-height: 14px;
  bottom: 8px;
  font-size: 1.4rem;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
form .form-item > label {
  opacity: 0;
}
form .form-item.focus {
  box-shadow: 0 0 0 4px #E5891C;
  filter: drop-shadow(0px 0px 4px #E5891C);
}
form .form-item.focus > label {
  opacity: 1;
}
form input[type=submit] {
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #E5891C;
  background-color: #E5891C;
  color: #ffffff;
  width: 100%;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  border-radius: 0;
}
form input[type=number],
form input[type=text],
form input[type=tel],
form input[type=email],
form input[type=url],
form input[type=password],
form textarea {
  border-radius: 0;
  flex: 0 0 100%;
  width: 100%;
  font-size: 1.8rem;
  height: 48px;
  font-style: normal;
  font-stretch: normal;
  color: #DBDEEF;
  padding: 18px 20px;
  border: 0 none;
  outline: 1px solid rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
}
form input[type=number]::-webkit-input-placeholder,
form input[type=text]::-webkit-input-placeholder,
form input[type=tel]::-webkit-input-placeholder,
form input[type=email]::-webkit-input-placeholder,
form input[type=url]::-webkit-input-placeholder,
form input[type=password]::-webkit-input-placeholder,
form textarea::-webkit-input-placeholder {
  color: #DBDEEF;
  opacity: 0.6;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
}
form input[type=number]::-moz-placeholder,
form input[type=text]::-moz-placeholder,
form input[type=tel]::-moz-placeholder,
form input[type=email]::-moz-placeholder,
form input[type=url]::-moz-placeholder,
form input[type=password]::-moz-placeholder,
form textarea::-moz-placeholder {
  color: #DBDEEF;
  opacity: 0.6;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
}
form input[type=number]:-moz-placeholder,
form input[type=text]:-moz-placeholder,
form input[type=tel]:-moz-placeholder,
form input[type=email]:-moz-placeholder,
form input[type=url]:-moz-placeholder,
form input[type=password]:-moz-placeholder,
form textarea:-moz-placeholder {
  color: #DBDEEF;
  opacity: 0.6;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
}
form input[type=number]:-ms-input-placeholder,
form input[type=text]:-ms-input-placeholder,
form input[type=tel]:-ms-input-placeholder,
form input[type=email]:-ms-input-placeholder,
form input[type=url]:-ms-input-placeholder,
form input[type=password]:-ms-input-placeholder,
form textarea:-ms-input-placeholder {
  color: #DBDEEF;
  opacity: 0.6;
  font-size: 1.8rem;
  letter-spacing: 0.1em;
}
form input[type=number]:focus,
form input[type=text]:focus,
form input[type=tel]:focus,
form input[type=email]:focus,
form input[type=url]:focus,
form input[type=password]:focus,
form textarea:focus {
  outline: 1px solid #e5891c;
}
form input[type=number]:focus::-webkit-input-placeholder,
form input[type=text]:focus::-webkit-input-placeholder,
form input[type=tel]:focus::-webkit-input-placeholder,
form input[type=email]:focus::-webkit-input-placeholder,
form input[type=url]:focus::-webkit-input-placeholder,
form input[type=password]:focus::-webkit-input-placeholder,
form textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
form input[type=number]:focus::-moz-placeholder,
form input[type=text]:focus::-moz-placeholder,
form input[type=tel]:focus::-moz-placeholder,
form input[type=email]:focus::-moz-placeholder,
form input[type=url]:focus::-moz-placeholder,
form input[type=password]:focus::-moz-placeholder,
form textarea:focus::-moz-placeholder {
  color: transparent;
}
form input[type=number]:focus:-moz-placeholder,
form input[type=text]:focus:-moz-placeholder,
form input[type=tel]:focus:-moz-placeholder,
form input[type=email]:focus:-moz-placeholder,
form input[type=url]:focus:-moz-placeholder,
form input[type=password]:focus:-moz-placeholder,
form textarea:focus:-moz-placeholder {
  color: transparent;
}
form input[type=number]:focus:-ms-input-placeholder,
form input[type=text]:focus:-ms-input-placeholder,
form input[type=tel]:focus:-ms-input-placeholder,
form input[type=email]:focus:-ms-input-placeholder,
form input[type=url]:focus:-ms-input-placeholder,
form input[type=password]:focus:-ms-input-placeholder,
form textarea:focus:-ms-input-placeholder {
  color: transparent;
}
form input[type=number]:hover,
form input[type=text]:hover,
form input[type=tel]:hover,
form input[type=email]:hover,
form input[type=url]:hover,
form input[type=password]:hover,
form textarea:hover {
  outline: 1px solid #e5891c;
}

body.show-modal {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  -webkit-overflow-scrolling: touch;
  background: #1D2F4F;
}
body.show-modal .modal {
  display: flex;
}
body.show-modal .bg-overlay {
  display: block;
}

.modal {
  display: none;
  justify-content: center;
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
}
.modal-block {
  position: relative;
  width: 100%;
  max-width: 600px;
  background-color: #E5891C;
  height: 300px;
  padding: 20px;
  display: flex;
  justify-content: center;
  z-index: 600;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .modal-block {
    max-width: 500px;
    height: 260px;
  }
}
@media screen and (max-width: 640px) {
  .modal-block {
    max-width: 320px;
    height: 230px;
  }
}
.modal-block .close-block .close {
  font-size: 1.6rem;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0 none;
  cursor: url(/images/icons/cursor-hover.svg), pointer;
  min-width: 20px;
  width: 20px;
  height: 20px;
}
.modal-close {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(/images/svg/close.svg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: contain;
}
.modal-text {
  color: #ffffff;
}
.modal-content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-title {
  text-align: center;
  font-size: 3.3rem;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .modal-title {
    font-size: 2.4rem;
  }
}
.modal-desc {
  padding-top: 10px;
  text-align: center;
  font-size: 1.4rem;
}
@media screen and (max-width: 768px) {
  .modal-desc {
    font-size: 1.2rem;
  }
}

.bg-overlay {
  position: fixed;
  display: none;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  z-index: 500;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.header {
  will-change: transform, opacity;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  min-width: 320px;
  z-index: 800;
  position: absolute;
  background-color: transparent;
  transition: background-color 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.header-wrapper {
  position: relative;
}
.header-site-width {
  position: relative;
  z-index: 5;
}
.header-row {
  position: relative;
}
@media screen and (max-width: 640px) {
  .header-row {
    justify-content: center;
  }
}
.header-logo {
  display: block;
  flex: 0 0 30%;
  padding-top: 30px;
}
.header-logo-link {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 170px;
}
@media screen and (max-width: 767px) {
  .header-logo-link {
    flex-wrap: nowrap;
  }
}
.header-logo-icon {
  max-width: 170px;
  flex: 0 0 100%;
}
.header-logo-text {
  padding-top: 10px;
  flex: 0 0 100%;
}
.header-logo-text--text {
  display: none;
}
.header-logo .img {
  object-fit: contain;
}
.header-lang {
  position: relative;
  padding-left: 5px;
  padding-right: 10px;
  height: 30px;
  align-items: center;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}
@media screen and (max-width: 640px) {
  .header-lang {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    order: 1;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 10px;
    border-right: 0 none;
  }
}
.header-lang:hover .header-lang-modal {
  display: block;
}
.header-lang-active {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
@media screen and (max-width: 640px) {
  .header-lang-active {
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 48px;
    width: 48px;
    justify-content: center;
    line-height: 1;
  }
}
.header-lang-active:after {
  content: "";
  position: relative;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  width: 8px;
  height: 4px;
  display: block;
  margin-left: 5px;
  transition: all 0.3s linear;
}
@media screen and (max-width: 640px) {
  .header-lang-active:after {
    display: none;
  }
}
.header-lang-modal {
  position: absolute;
  left: 0;
  top: 25px;
  width: 100%;
  max-width: 47px;
  background-color: #ffffff;
  display: none;
}
@media screen and (max-width: 640px) {
  .header-lang-modal {
    top: 58px;
    left: 0;
  }
}
.header-lang-btn {
  color: #5b7095;
  padding: 10px;
  border: 0 none;
  font-size: 1.4rem;
  text-transform: uppercase;
  height: 36px;
}
.header-lang-btn:hover {
  background-color: rgba(91, 112, 149, 0.15);
}
.header-lang-btn.active {
  color: #E5891C;
}
.header-right {
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
  flex: 0 0 70%;
}
@media screen and (max-width: 767px) {
  .header-right {
    padding-top: 14px;
    z-index: 500;
  }
}
@media screen and (max-width: 640px) {
  .header-right {
    width: auto;
    flex: 0 0 auto;
    padding: 10px 0 0;
  }
}
.header-right-block {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.header-menu {
  order: 3;
  padding-top: 50px;
}
.header-menu-block {
  display: flex;
}
@media screen and (max-width: 767px) {
  .header-menu-block {
    display: none;
  }
}
.header-menu-before {
  position: relative;
  padding-right: 11px;
}
.header-menu-before span {
  z-index: 10;
  position: relative;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  height: 30px;
  display: flex;
  align-items: center;
}
.header-menu-before:before {
  z-index: 0;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 1000px;
  height: 30px;
  background-color: #E5891C;
  border-radius: 0 11.5px 11.5px 0;
}
@media screen and (max-width: 767px) {
  .header-menu-before {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header-menu-nav {
    padding: 70px 52px 70px;
    overflow-y: auto;
    height: 100%;
  }
}
.header-menu-nav .nav-ul {
  display: flex;
}
@media screen and (max-width: 767px) {
  .header-menu-nav .nav-ul {
    display: block;
    max-width: 350px;
  }
}
.header-menu-nav .nav-ul-sub {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 425px;
  visibility: hidden;
  opacity: 0;
  background-color: #334E7D;
  margin-top: 34px;
  z-index: 0;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  outline: 1px solid #516588;
  box-shadow: 0 8px 10px rgba(29, 47, 79, 0.15);
}
@media screen and (max-width: 768px) {
  .header-menu-nav .nav-ul-sub {
    width: 410px;
  }
}
@media screen and (max-width: 767px) {
  .header-menu-nav .nav-ul-sub {
    transition: none;
    width: 100%;
    position: relative;
    background-color: transparent;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    height: 100%;
    box-shadow: none;
    display: none;
    outline: none;
  }
}
.header-menu-nav .nav-li {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .header-menu-nav .nav-li {
    padding: 30px 0 0;
  }
  .header-menu-nav .nav-li:first-of-type {
    padding-top: 0;
  }
}
@media screen and (min-width: 768px) {
  .header-menu-nav .nav-li:hover .nav-ul-sub {
    display: block;
    visibility: visible;
    height: auto;
    opacity: 1;
  }
}
.header-menu-nav .nav-li-sub {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 768px) {
  .header-menu-nav .nav-li-sub {
    padding-top: 0;
  }
}
.header-menu-nav .nav-link {
  position: relative;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 27px;
  z-index: 5;
  color: #ffffff;
  transition: color 0.3s linear;
}
@media screen and (max-width: 767px) {
  .header-menu-nav .nav-link {
    font-size: 2.4rem;
  }
}
.header-menu-nav .nav-link:before {
  content: "";
  height: 1px;
  width: 0;
  position: absolute;
  left: 0;
  bottom: -3px;
  background-color: #E5891C;
  transition: all 0.2s linear;
}
@media screen and (max-width: 767px) {
  .header-menu-nav .nav-link:before {
    display: none;
  }
}
.header-menu-nav .nav-link.active {
  color: #E5891C;
}
.header-menu-nav .nav-link.nav-link-arrow {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .header-menu-nav .nav-link.nav-link-arrow {
    display: inline-flex;
  }
}
.header-menu-nav .nav-link.nav-link-arrow:after {
  content: "";
  height: 9px;
  width: 10px;
  margin-left: 7px;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  background-position: center center;
  transform: rotate(0);
  transition: all 0.3s linear;
}
@media screen and (min-width: 768px) {
  .header-menu-nav .nav-link.nav-link-arrow:hover:after {
    transform: rotate(180deg);
    background-image: url("/images/svg/arrow-bot-hover.svg");
  }
}
.header-menu-nav .nav-link.nav-link-arrow.open {
  color: #E5891C;
}
.header-menu-nav .nav-link.nav-link-arrow.open:after {
  transform: rotate(180deg);
  background-image: url("/images/svg/arrow-bot-hover.svg");
}
@media screen and (min-width: 768px) {
  .header-menu-nav .nav-link:hover {
    color: #E5891C;
  }
  .header-menu-nav .nav-link:hover:before {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    background-color: #E5891C;
  }
}
@media screen and (min-width: 768px) and (max-width: 767px) {
  .header-menu-nav .nav-link:hover:before {
    display: none;
  }
}
.header-menu-nav .nav-link-sub {
  font-weight: 400;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  padding: 10px 20px;
  display: block;
}
@media screen and (min-width: 768px) {
  .header-menu-nav .nav-link-sub:hover {
    background-color: #2C4269;
  }
}
.header-menu-nav .nav-link-sub.active {
  color: #E5891C;
  font-weight: 700;
}
.header-menu-nav .nav-link-sub:before {
  display: none;
}
.header-mobile {
  display: none;
  transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100%;
  padding-top: 70px;
  background: linear-gradient(267.74deg, #486AA6 0.56%, #1D2F4F 98.79%);
  right: 0;
  height: 100%;
  min-height: 100vh;
  z-index: 1000;
  transform: translateX(-120%);
}
@media screen and (max-width: 768px) {
  .header-mobile {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .header-mobile.menu-opened {
    transform: translateX(0);
  }
}
.header-mobile-nav {
  padding: 70px 0;
  overflow-y: auto;
  height: 100%;
}
.header-mobile .nav-ul {
  display: block;
  padding: 0 52px;
}
@media screen and (max-width: 640px) {
  .header-mobile .nav-ul {
    padding: 0 30px;
  }
}
.header-mobile .nav-ul-sub {
  transition: none;
  width: 100%;
  position: relative;
  background-color: transparent;
  visibility: visible;
  opacity: 1;
  height: 100%;
  box-shadow: none;
  display: none;
  outline: none;
  padding: 0;
}
.header-mobile .nav-li {
  padding: 30px 0 0;
}
.header-mobile .nav-li-sub {
  padding: 10px 0;
}
.header-mobile .nav-li-sub:first-of-type {
  padding: 22px 0 10px;
}
.header-mobile .nav-link {
  position: relative;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  line-height: 27px;
  z-index: 5;
  color: #ffffff;
  transition: color 0.3s linear;
  font-size: 2.4rem;
}
.header-mobile .nav-link.active {
  color: #E5891C;
}
.header-mobile .nav-link.nav-link-arrow {
  display: inline-flex;
  align-items: center;
}
.header-mobile .nav-link.nav-link-arrow:after {
  content: "";
  height: 9px;
  width: 10px;
  margin-left: 7px;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  background-position: center center;
  transform: rotate(0);
  transition: all 0.3s linear;
}
.header-mobile .nav-link.nav-link-arrow.open {
  color: #E5891C;
}
.header-mobile .nav-link.nav-link-arrow.open:after {
  transform: rotate(180deg);
  background-image: url("/images/svg/arrow-bot-hover.svg");
}
.header-mobile .nav-link-sub {
  font-weight: 400;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  display: block;
  padding: 0;
  line-height: 110%;
}
.header-mobile .nav-link-sub.active {
  color: #E5891C;
  font-weight: 700;
}
.header-burger {
  padding-top: 10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1200;
  display: none;
}
@media screen and (max-width: 767px) {
  .header-burger {
    display: block;
  }
}
.header-burger .burger {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ccc;
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}
.header-burger .burger:focus {
  outline: none;
}
.header-burger .burger span {
  display: block;
  position: absolute;
  top: 25px;
  left: 16px;
  right: 16px;
  height: 1px;
  background: #ffffff;
}
.header-burger .burger span::before, .header-burger .burger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
  content: "";
}
.header-burger .burger span::before {
  top: -5px;
}
.header-burger .burger span::after {
  bottom: -5px;
}
.header-burger .burger.burger-left {
  /* when menu open: */
}
.header-burger .burger.burger-left span {
  background-color: #ffffff;
  transition: transform 0.3s;
}
.header-burger .burger.burger-left span:before, .header-burger .burger.burger-left span:after {
  background-color: #ffffff;
}
.header-burger .burger.burger-left span:before {
  transform-origin: top right;
  transition: transform 0.3s, width 0.3s, top 0.3s;
}
.header-burger .burger.burger-left span:after {
  transform-origin: bottom right;
  transition: transform 0.3s, width 0.3s, bottom 0.3s;
}
.header-burger .burger.burger-left.is-active span {
  background-color: #ffffff;
  transform: rotate(180deg);
}
.header-burger .burger.burger-left.is-active span:before, .header-burger .burger.burger-left.is-active span:after {
  background-color: #ffffff;
  width: 50%;
}
.header-burger .burger.burger-left.is-active span:before {
  top: 0;
  transform: translateX(8px) translateY(0px) rotate(45deg);
}
.header-burger .burger.burger-left.is-active span:after {
  bottom: 0;
  transform: translateX(8px) translateY(0px) rotate(-45deg);
}
.header.home {
  padding-bottom: 0;
}
@media screen and (max-width: 767px) {
  .header.home {
    max-width: 100%;
  }
}
.header.home .header-wrapper:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 100%;
  max-width: 100vw;
  height: 318px;
  background: linear-gradient(180deg, #E5891C 0%, transparent 100%);
  display: block;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  .header.home .header-wrapper:after {
    height: 210px;
  }
}
@media screen and (max-width: 767px) {
  .header.home .header-wrapper:after {
    height: 170px;
    max-width: 100%;
  }
}
.header.home .header-social {
  display: flex;
  justify-content: left;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  padding-left: 5px;
}
@media screen and (max-width: 640px) {
  .header.home .header-social {
    display: none;
  }
}
.header.home .header-social-item {
  height: 30px;
  padding-right: 5px;
  padding-top: 3px;
  padding-left: 5px;
}
.header.home .header-social-link {
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.header.home .header-social-link.facebook {
  background-image: url(/images/svg/facebook.svg);
}
.header.home .header-social-link.facebook:hover {
  opacity: 0.5;
}
.header.home .header-social-link.instagram {
  background-image: url(/images/svg/instagram.svg);
}
.header.home .header-social-link.instagram:hover {
  opacity: 0.5;
}
.header.home .header-right {
  flex: 0 0 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  .header.home .header-right {
    padding-top: 14px;
    z-index: 1100;
  }
}
@media screen and (max-width: 640px) {
  .header.home .header-right {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    justify-content: center;
    height: 58px;
  }
}
.header.home .header-right-block {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header.home .header-logo {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
  order: 2;
  padding-top: 56px;
}
@media screen and (max-width: 640px) {
  .header.home .header-logo {
    padding-top: 33px;
  }
}
.header.home .header-logo-link {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  transition: none;
}
@media screen and (max-width: 767px) {
  .header.home .header-logo-link {
    max-width: 300px;
    margin: 0 auto;
  }
}
.header.home .header-logo-icon {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 170px;
}
@media screen and (max-width: 767px) {
  .header.home .header-logo-icon {
    flex: 0 0 33%;
    max-width: 33%;
  }
}
.header.home .header-logo-text {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 625px;
  margin-left: 63px;
}
@media screen and (max-width: 767px) {
  .header.home .header-logo-text {
    margin-left: 20px;
    max-width: 77%;
  }
}
@media screen and (max-width: 767px) {
  .header.home .header-logo-text img {
    max-width: 100%;
  }
}
.header.home .header-logo-text--text {
  display: none;
}
@media screen and (max-width: 767px) {
  .header.home .header-logo-text--text {
    color: #E5891C;
    font-weight: 700;
    font-family: "Oswald", sans-serif;
    letter-spacing: 0.27em;
    line-height: 18px;
    display: block;
    text-transform: uppercase;
    font-size: 1rem;
  }
}
.header.home .header-logo .img {
  object-fit: contain;
}
.header.home .header-phones {
  position: relative;
  height: 30px;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 640px) {
  .header.home .header-phones {
    border-left: 0 none;
  }
}
.header.home .header-phones:hover .header-phones-modal {
  opacity: 1;
  visibility: visible;
}
.header.home .header-phones-active {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  letter-spacing: 1px;
}
.header.home .header-phones-active:after {
  content: "";
  position: relative;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  width: 8px;
  height: 4px;
  display: block;
  margin-left: 5px;
  transition: all 0.3s linear;
}
.header.home .header-phones-modal {
  position: absolute;
  left: 0;
  top: 25px;
  width: 233px;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear, visibility 0.3s linear;
}
@media (max-width: 1280px) {
  .header.home .header-phones-modal {
    left: auto;
    right: 0;
  }
}
.header.home .header-phones-block {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.header.home .header-phones-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 10px;
}
.header.home .header-phones-item.center {
  text-align: center;
}
.header.home .header-phones-item:last-of-type {
  padding-bottom: 0;
}
.header.home .header-phones-phone {
  color: #5b7095;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 24px;
  letter-spacing: 1px;
}
.header.home .header-phones-time {
  border-top: 1px solid rgba(91, 112, 149, 0.15);
  padding: 10px 20px;
  color: #5b7095;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.header.home .header-phones-time-item {
  flex: 1;
  height: 27px;
  display: flex;
  align-items: center;
}
.header.home .header-phones-time-item:last-of-type {
  border-left: 1px solid rgba(91, 112, 149, 0.15);
  padding-left: 20px;
}
@media screen and (max-width: 767px) {
  .header.not-home .header-row {
    height: 58px;
    justify-content: center;
  }
}
.header.not-home .header-phones {
  position: relative;
  height: 30px;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 640px) {
  .header.not-home .header-phones {
    display: none;
  }
}
.header.not-home .header-phones:hover .header-phones-modal {
  opacity: 1;
  visibility: visible;
}
.header.not-home .header-phones-active {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  letter-spacing: 1px;
}
.header.not-home .header-phones-active:after {
  content: "";
  position: relative;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  width: 8px;
  height: 4px;
  display: block;
  margin-left: 5px;
  transition: all 0.3s linear;
}
.header.not-home .header-phones-modal {
  position: absolute;
  left: 0;
  top: 25px;
  width: 233px;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear, visibility 0.3s linear;
}
@media (max-width: 1280px) {
  .header.not-home .header-phones-modal {
    left: auto;
    right: 0;
  }
}
.header.not-home .header-phones-block {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.header.not-home .header-phones-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 10px;
}
.header.not-home .header-phones-item.center {
  text-align: center;
}
.header.not-home .header-phones-item:last-of-type {
  padding-bottom: 0;
}
.header.not-home .header-phones-phone {
  color: #5b7095;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 24px;
  letter-spacing: 1px;
}
.header.not-home .header-phones-time {
  border-top: 1px solid rgba(91, 112, 149, 0.15);
  padding: 10px 20px;
  color: #5b7095;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.header.not-home .header-phones-time-item {
  flex: 1;
  height: 27px;
  display: flex;
  align-items: center;
}
.header.not-home .header-phones-time-item:last-of-type {
  border-left: 1px solid rgba(91, 112, 149, 0.15);
  padding-left: 20px;
}
.header.not-home .header-social {
  display: flex;
  justify-content: left;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  padding-left: 5px;
}
@media screen and (max-width: 640px) {
  .header.not-home .header-social {
    display: none;
  }
}
.header.not-home .header-social-item {
  height: 30px;
  padding-right: 5px;
  padding-top: 3px;
  padding-left: 5px;
}
.header.not-home .header-social-link {
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.header.not-home .header-social-link.facebook {
  background-image: url(/images/svg/facebook.svg);
}
.header.not-home .header-social-link.facebook:hover {
  background-image: url(/images/svg/facebook-hover.svg);
}
.header.not-home .header-social-link.instagram {
  background-image: url(/images/svg/instagram.svg);
}
.header.not-home .header-social-link.instagram:hover {
  background-image: url(/images/svg/instagram-hover.svg);
}
.header.not-home .header-logo {
  display: block;
  flex: 0 0 30%;
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  .header.not-home .header-logo {
    display: none;
  }
}
@media screen and (max-width: 640px) {
  .header.not-home .header-logo {
    padding-top: 10px;
    justify-content: center;
    display: flex;
    flex: 0 0 100%;
    height: 58px;
    z-index: 1100;
  }
}
.header.not-home .header-logo-link {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 170px;
  transition: none;
}
@media screen and (max-width: 767px) {
  .header.not-home .header-logo-link {
    flex-wrap: nowrap;
  }
}
.header.not-home .header-logo-icon {
  max-width: 170px;
  flex: 0 0 100%;
}
@media screen and (max-width: 767px) {
  .header.not-home .header-logo-icon {
    max-width: 30px;
    flex: 0 0 auto;
    display: block;
  }
}
.header.not-home .header-logo-text {
  padding-top: 10px;
  flex: 0 0 100%;
}
@media screen and (max-width: 767px) {
  .header.not-home .header-logo-text {
    padding-top: 0;
    margin-left: 10px;
    flex: 0 0 auto;
    display: block;
    max-width: 115px;
  }
}
.header.not-home .header-logo .img {
  object-fit: contain;
}
.header.not-home .header-right {
  z-index: 1100;
}
@media screen and (max-width: 767px) {
  .header.not-home .header-right {
    padding-left: 0;
    padding-right: 0;
    flex: 0 0 100%;
  }
}
.header.not-home .header-menu {
  padding-top: 20px;
}
@media screen and (max-width: 767px) {
  .header.not-home .header-menu {
    padding: 0;
    flex: 0 0 100%;
  }
}
.header.menu-fixed {
  position: fixed;
  background-color: #E5891C;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
}
.header.menu-fixed.home {
  padding-bottom: 0;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home {
    max-width: 100%;
  }
}
.header.menu-fixed.home .header-wrapper:after {
  display: none;
}
.header.menu-fixed.home .header-row {
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-row {
    height: 70px;
    align-items: flex-start;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-lang {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    order: 1;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 10px;
    border-right: 0 none;
  }
}
.header.menu-fixed.home .header-lang:hover .header-lang-modal {
  display: block;
}
.header.menu-fixed.home .header-lang-active {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-lang-active {
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 48px;
    width: 48px;
    justify-content: center;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-lang-active:after {
    display: none;
  }
}
.header.menu-fixed.home .header-lang-modal {
  position: absolute;
  left: 0;
  top: 25px;
  width: 100%;
  max-width: 47px;
  background-color: #ffffff;
  display: none;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-lang-modal {
    top: 65px;
  }
}
.header.menu-fixed.home .header-lang-btn {
  color: #5b7095;
  padding: 10px;
  border: 0 none;
  font-size: 1.4rem;
  text-transform: uppercase;
  height: 36px;
}
@media screen and (min-width: 768px) {
  .header.menu-fixed.home .header-lang-btn:hover {
    background-color: rgba(91, 112, 149, 0.15);
  }
}
.header.menu-fixed.home .header-lang-btn.active {
  color: #E5891C;
}
.header.menu-fixed.home .header-phones:hover .header-phones-modal {
  display: block;
}
.header.menu-fixed.home .header-phones-active {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
}
.header.menu-fixed.home .header-phones-active:after {
  content: "";
  position: relative;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  width: 8px;
  height: 4px;
  display: block;
  margin-left: 5px;
  transition: all 0.3s linear;
}
.header.menu-fixed.home .header-phones-modal {
  position: absolute;
  left: 0;
  top: 25px;
  width: 233px;
  background-color: #ffffff;
  display: none;
}
@media (max-width: 1280px) {
  .header.menu-fixed.home .header-phones-modal {
    left: auto;
    right: 0;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-phones-modal {
    right: -50%;
  }
}
.header.menu-fixed.home .header-phones-block {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.header.menu-fixed.home .header-phones-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 10px;
}
.header.menu-fixed.home .header-phones-item.center {
  text-align: center;
}
.header.menu-fixed.home .header-phones-item:last-of-type {
  padding-bottom: 0;
}
.header.menu-fixed.home .header-phones-phone {
  color: #5b7095;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 24px;
}
.header.menu-fixed.home .header-phones-time {
  border-top: 1px solid rgba(91, 112, 149, 0.15);
  padding: 20px;
  color: #5b7095;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.header.menu-fixed.home .header-logo {
  display: block;
  padding-top: 10px;
  flex: 0 0 124px;
  max-width: 124px;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-logo {
    display: none;
  }
}
.header.menu-fixed.home .header-logo-link {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 94px;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-logo-link {
    flex-wrap: nowrap;
  }
}
.header.menu-fixed.home .header-logo-icon {
  max-width: 76px;
  flex: 0 0 100%;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-logo-icon {
    max-width: 30px;
    flex: 0 0 auto;
    display: block;
  }
}
.header.menu-fixed.home .header-logo-text {
  padding-top: 0;
  flex: 0 0 100%;
  margin-left: 0;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-logo-text {
    padding-top: 0;
    margin-left: 10px;
    flex: 0 0 auto;
    display: block;
    max-width: 115px;
  }
}
.header.menu-fixed.home .header-logo .img {
  object-fit: contain;
}
.header.menu-fixed.home .header-menu {
  order: 2;
  flex: 1;
  padding-top: 0;
  padding-left: 40px;
}
@media (max-width: 1024px) {
  .header.menu-fixed.home .header-menu {
    padding-left: 35px;
  }
}
@media (max-width: 992px) {
  .header.menu-fixed.home .header-menu {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-menu {
    padding-top: 0;
  }
}
.header.menu-fixed.home .header-menu-before {
  display: none;
}
.header.menu-fixed.home .header-right {
  order: 3;
  flex: 1;
  max-width: 360px;
  padding: 0 5px 0 0;
}
@media (max-width: 1024px) {
  .header.menu-fixed.home .header-right {
    max-width: 340px;
  }
}
@media (max-width: 900px) {
  .header.menu-fixed.home .header-right {
    max-width: 240px;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.home .header-right {
    z-index: 1100;
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    height: 58px;
  }
}
.header.menu-fixed.not-home .header-row {
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-row {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-lang {
    padding-left: 0;
    padding-right: 0;
    height: auto;
    order: 1;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 10px;
    border-right: 0 none;
  }
}
.header.menu-fixed.not-home .header-lang:hover .header-lang-modal {
  display: block;
}
.header.menu-fixed.not-home .header-lang-active {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-lang-active {
    border: 1px solid rgba(255, 255, 255, 0.1);
    height: 48px;
    width: 48px;
    justify-content: center;
    line-height: 1;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-lang-active:after {
    display: none;
  }
}
.header.menu-fixed.not-home .header-lang-modal {
  position: absolute;
  left: 0;
  top: 25px;
  width: 100%;
  max-width: 47px;
  background-color: #ffffff;
  display: none;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-lang-modal {
    top: 65px;
  }
}
.header.menu-fixed.not-home .header-lang-btn {
  color: #5b7095;
  padding: 10px;
  border: 0 none;
  font-size: 1.4rem;
  text-transform: uppercase;
  height: 36px;
}
@media screen and (min-width: 768px) {
  .header.menu-fixed.not-home .header-lang-btn:hover {
    background-color: rgba(91, 112, 149, 0.15);
  }
}
.header.menu-fixed.not-home .header-lang-btn.active {
  color: #E5891C;
}
.header.menu-fixed.not-home .header-phones {
  position: relative;
  height: 30px;
  align-items: center;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-phones {
    display: none;
  }
}
.header.menu-fixed.not-home .header-phones:hover .header-phones-modal {
  display: block;
}
.header.menu-fixed.not-home .header-phones-active {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
}
.header.menu-fixed.not-home .header-phones-active:after {
  content: "";
  position: relative;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  width: 8px;
  height: 4px;
  display: block;
  margin-left: 5px;
  transition: all 0.3s linear;
}
.header.menu-fixed.not-home .header-phones-modal {
  position: absolute;
  left: 0;
  top: 25px;
  width: 233px;
  background-color: #ffffff;
  display: none;
}
@media (max-width: 1280px) {
  .header.menu-fixed.not-home .header-phones-modal {
    left: auto;
    right: 0;
  }
}
.header.menu-fixed.not-home .header-phones-block {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-phones-block {
    display: none;
  }
}
.header.menu-fixed.not-home .header-phones-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding-bottom: 10px;
}
.header.menu-fixed.not-home .header-phones-item.center {
  text-align: center;
}
.header.menu-fixed.not-home .header-phones-item:last-of-type {
  padding-bottom: 0;
}
.header.menu-fixed.not-home .header-phones-phone {
  color: #5b7095;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 24px;
}
.header.menu-fixed.not-home .header-phones-time {
  border-top: 1px solid rgba(91, 112, 149, 0.15);
  padding: 20px;
  color: #5b7095;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.header.menu-fixed.not-home .header-logo {
  display: block;
  padding-top: 10px;
  flex: 0 0 124px;
  max-width: 124px;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-logo {
    padding-top: 10px;
    justify-content: center;
    display: flex;
    flex: 0 0 100%;
    height: 58px;
    z-index: 1100;
    max-width: 100%;
  }
}
.header.menu-fixed.not-home .header-logo-link {
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 94px;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-logo-link {
    flex-wrap: nowrap;
  }
}
.header.menu-fixed.not-home .header-logo-icon {
  max-width: 76px;
  flex: 0 0 100%;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-logo-icon {
    max-width: 30px;
    flex: 0 0 auto;
    display: block;
  }
}
.header.menu-fixed.not-home .header-logo-text {
  padding-top: 0;
  flex: 0 0 100%;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed.not-home .header-logo-text {
    padding-top: 0;
    margin-left: 10px;
    flex: 0 0 auto;
    display: block;
    max-width: 115px;
  }
}
.header.menu-fixed.not-home .header-logo .img {
  object-fit: contain;
}
.header.menu-fixed.not-home .header-menu {
  order: 2;
  flex: 1;
  padding-left: 40px;
  padding-top: 0;
}
@media (max-width: 992px) {
  .header.menu-fixed.not-home .header-menu {
    padding-left: 0;
    padding-right: 0;
  }
}
.header.menu-fixed.not-home .header-menu-before {
  display: none;
}
.header.menu-fixed.not-home .header-right {
  order: 3;
  flex: 1;
  max-width: 360px;
  padding: 0 5px 0 0;
}
@media (max-width: 1024px) {
  .header.menu-fixed.not-home .header-right {
    max-width: 340px;
  }
}
@media (max-width: 900px) {
  .header.menu-fixed.not-home .header-right {
    max-width: 240px;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed .header-site-width {
    padding-left: 34px;
    padding-right: 34px;
  }
}
@media screen and (max-width: 640px) {
  .header.menu-fixed .header-site-width {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed .header-burger {
    display: block;
  }
}
.header.menu-fixed .header-row {
  height: 70px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header.menu-fixed .header-logo {
    display: none;
  }
}
@media (max-width: 900px) {
  .header.menu-fixed .header-social {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header.menu-fixed .header-menu {
    padding-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed .header-menu-block {
    display: none;
  }
}
.header.menu-fixed .header-menu-before {
  position: relative;
  padding-right: 11px;
}
.header.menu-fixed .header-menu-before span {
  z-index: 10;
  position: relative;
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  height: 30px;
  display: flex;
  align-items: center;
}
.header.menu-fixed .header-menu-before:before {
  z-index: 0;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 500%;
  height: 30px;
  background-color: #E5891C;
  border-radius: 0 11.5px 11.5px 0;
}
@media screen and (max-width: 767px) {
  .header.menu-fixed .header-menu-before {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed .header-menu-nav {
    padding: 70px 52px 70px;
    overflow-y: auto;
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed .header-menu-nav .nav-ul {
    display: block;
    max-width: 350px;
  }
}
@media screen and (max-width: 768px) {
  .header.menu-fixed .header-menu-nav .nav-ul-sub {
    transition: none;
    width: 100%;
    position: relative;
    background-color: transparent;
    margin-top: 0;
    visibility: visible;
    opacity: 1;
    height: 100%;
    display: none;
  }
}
.header.menu-fixed .header-menu-nav .nav-li {
  padding-left: 15px;
  padding-right: 15px;
}
.header.menu-fixed .header-menu-nav .nav-li:first-of-type {
  padding-left: 0;
}
@media (max-width: 900px) {
  .header.menu-fixed .header-menu-nav .nav-li {
    padding-right: 10px;
  }
}
.header.menu-fixed .header-menu-nav .nav-li-sub {
  padding-left: 0;
  padding-right: 0;
}
.header.menu-fixed .header-menu-nav .nav-link {
  position: relative;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  line-height: 27px;
  z-index: 5;
  transition: none;
  font-size: 1.6rem;
}
@media (max-width: 1024px) {
  .header.menu-fixed .header-menu-nav .nav-link {
    font-size: 1.4rem;
  }
}
.header.menu-fixed .header-menu-nav .nav-link:before {
  content: "";
  height: 1px;
  width: 0;
  position: absolute;
  left: 0;
  bottom: -3px;
  background-color: #ffffff;
  transition: all 0.2s linear;
}
@media screen and (max-width: 767px) {
  .header.menu-fixed .header-menu-nav .nav-link:before {
    display: none;
  }
}
.header.menu-fixed .header-menu-nav .nav-link.nav-link-arrow {
  display: flex;
  align-items: center;
}
.header.menu-fixed .header-menu-nav .nav-link.nav-link-arrow.open {
  color: #E5891C;
}
.header.menu-fixed .header-menu-nav .nav-link.nav-link-arrow:after {
  content: "";
  height: 9px;
  width: 10px;
  margin-left: 7px;
  background-image: url("/images/svg/arrow-bot.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: contain;
  background-position: center center;
  transform: rotate(0);
  transition: all 0.3s linear;
}
.header.menu-fixed .header-menu-nav .nav-link.nav-link-arrow:hover:after {
  transform: rotate(180deg);
}
.header.menu-fixed .header-menu-nav .nav-link.nav-link-arrow.open {
  color: #E5891C;
}
.header.menu-fixed .header-menu-nav .nav-link.nav-link-arrow.open:after {
  transform: rotate(180deg);
  background-image: url("/images/svg/arrow-bot-hover.svg");
}
@media screen and (min-width: 768px) {
  .header.menu-fixed .header-menu-nav .nav-link:hover {
    color: #ffffff;
  }
  .header.menu-fixed .header-menu-nav .nav-link:hover:before {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -3px;
    background-color: #ffffff;
  }
}
@media screen and (min-width: 768px) and (max-width: 767px) {
  .header.menu-fixed .header-menu-nav .nav-link:hover:before {
    display: none;
  }
}
.header.menu-fixed .header-menu-nav .nav-link-sub {
  font-weight: 400;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  padding: 10px 20px;
  display: block;
}
.header.menu-fixed .header-menu-nav .nav-link-sub:hover {
  background-color: #2C4269;
}
.header.menu-fixed .header-menu-nav .nav-link-sub.active {
  color: #E5891C;
  font-weight: 700;
}
.header.menu-fixed .header-menu-nav .nav-link-sub:before {
  display: none;
}

@media screen and (max-width: 768px) {
  body.open-menu {
    overflow: hidden;
  }
}

.footer {
  margin-top: 100px;
  border-top: 1px solid rgba(219, 222, 239, 0.15);
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 640px) {
  .footer {
    margin-top: 70px;
  }
}
.footer-wrapper {
  padding-top: 50px;
  padding-bottom: 70px;
  font-size: 1.6rem;
  color: #ffffff;
}
@media screen and (max-width: 640px) {
  .footer-wrapper {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;
  }
}
.footer-row {
  display: flex;
  flex-wrap: nowrap;
}
.footer-left {
  flex: 0 0 50%;
  max-width: 50%;
}
@media screen and (max-width: 768px) {
  .footer-left {
    flex: 0 0 30%;
    max-width: 30%;
  }
}
@media screen and (max-width: 767px) {
  .footer-left {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.footer-left-title {
  font-size: 1.4rem;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  line-height: 21px;
  padding-top: 13px;
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .footer-left-title {
    font-size: 1.2rem;
    line-height: 18px;
  }
}
@media screen and (max-width: 640px) {
  .footer-left-title {
    font-size: 1rem;
    line-height: 15px;
  }
}
.footer-left-title--text {
  position: relative;
  padding-right: 11px;
}
@media screen and (max-width: 640px) {
  .footer-left-title--text {
    padding-top: 4px;
  }
}
.footer-left-title--text span {
  z-index: 10;
  position: relative;
  color: #5b7095;
}
.footer-left-title--text:before {
  z-index: 0;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 1000px;
  height: 23px;
  background-color: #1D2F4F;
  border-radius: 0 11.5px 11.5px 0;
}
.footer-left-copyright {
  padding-top: 65px;
  font-size: 1.4rem;
  line-height: 180%;
  text-transform: uppercase;
}
@media screen and (max-width: 640px) {
  .footer-left-copyright {
    font-size: 1rem;
  }
}
.footer-right {
  flex: 0 0 50%;
  max-width: 50%;
}
@media screen and (max-width: 768px) {
  .footer-right {
    flex: 0 0 70%;
    max-width: 70%;
  }
}
@media screen and (max-width: 767px) {
  .footer-right {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.footer-right-block {
  display: flex;
  flex-wrap: nowrap;
}
.footer-right-item {
  flex: 0 0 50%;
  max-width: 50%;
}
@media screen and (max-width: 767px) {
  .footer-right-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .footer-right-item:first-of-type {
    display: none;
  }
}
.footer-right-title--text {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 128%;
  padding-bottom: 10px;
  position: relative;
}
.footer-right-title--text:after {
  content: "";
  background-color: #E5891C;
  height: 1px;
  width: 58px;
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
}
.footer-right-lists {
  padding-top: 20px;
}
.footer-right-list {
  flex: 0 0 auto;
}
@media screen and (max-width: 768px) {
  .footer-right-list {
    flex: 0 0 33.33%;
  }
}
.footer-right-li {
  font-size: 1.6rem;
  padding-top: 15px;
}
@media screen and (max-width: 768px) {
  .footer-right-li {
    padding-top: 20px;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}
@media (max-width: 1900px) {
  .footer-right-li {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}
.footer-right-li:first-of-type {
  padding-top: 0;
}
.footer-right-phone {
  display: block;
  font-size: 1.8rem;
}
@media screen and (max-width: 640px) {
  .footer-right-phone {
    font-size: 1.4rem;
  }
}
.footer-right-phone:last-of-type {
  padding-top: 9px;
}
.footer-right-address {
  padding-top: 17px;
  font-size: 1.4rem;
}
.footer-right-social {
  padding-top: 20px;
  display: flex;
  justify-content: left;
}
.footer-right-social-item {
  height: 30px;
  padding-right: 10px;
  padding-top: 3px;
}
.footer-right-social-item:last-of-type {
  padding-left: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.footer-right-social-link {
  width: 24px;
  height: 24px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.footer-right-social-link.facebook {
  background-image: url("/images/svg/facebook.svg");
}
.footer-right-social-link.facebook:hover {
  background-image: url("/images/svg/facebook-hover.svg");
}
.footer-right-social-link.instagram {
  background-image: url("/images/svg/instagram.svg");
}
.footer-right-social-link.instagram:hover {
  background-image: url("/images/svg/instagram-hover.svg");
}
.footer-bottom {
  padding-top: 10px;
  padding-bottom: 10px;
}
.footer-bottom-wrapper {
  border-top: 3px solid #009478;
}
.footer-bottom-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .footer-bottom-row {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.footer-bottom-col {
  width: 100%;
  text-align: center;
}
.footer-bottom-desc {
  padding-top: 40px;
  font-size: 1.6rem;
}
.footer-bottom-copyright {
  padding-top: 20px;
  color: #009478;
  font-size: 1.6rem;
}

.consultation {
  padding-top: 100px;
}
@media screen and (max-width: 640px) {
  .consultation {
    padding-top: 70px;
  }
}
@media screen and (max-width: 768px) {
  .consultation .title-block {
    max-width: 340px;
    margin: 0 auto;
  }
}
.consultation .title-block .title {
  font-size: 3rem;
  line-height: 33px;
}
@media screen and (max-width: 768px) {
  .consultation .title-block .title {
    font-size: 2.4rem;
  }
}
.consultation-wrapper {
  position: relative;
  z-index: 10;
}
.consultation-row {
  padding-top: 53px;
}
@media screen and (max-width: 768px) {
  .consultation-row {
    padding-top: 30px;
  }
}
.consultation-col {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .consultation-col {
    padding: 0;
  }
}
.consultation-item {
  color: #ffffff;
  max-width: 365px;
}
.consultation-title--title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 53px;
}
.consultation-desc {
  padding-top: 20px;
  font-size: 1.4rem;
  line-height: 180%;
}
.consultation-btn {
  padding-top: 76px;
}
.consultation .form {
  width: 100%;
}
.consultation .form-block-row {
  display: flex;
}
.consultation .form-col {
  flex: 0 0 33.33%;
  max-width: 380px;
}
@media screen and (max-width: 768px) {
  .consultation .form-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.consultation .form .btn {
  max-width: 380px;
}

.p-home {
  padding-top: 310px;
}
@media screen and (max-width: 767px) {
  .p-home {
    padding-top: 210px;
  }
}
@media screen and (max-width: 640px) {
  .p-home {
    padding-top: 170px;
  }
}

.main-slider {
  padding-top: 37px;
}
@media screen and (max-width: 767px) {
  .main-slider {
    padding-top: 0;
  }
}
.main-slider-container {
  position: relative;
}
.main-slider-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 0;
}
.main-slider-bg--line {
  height: 318px;
  width: 100%;
  background-color: rgba(30, 48, 81, 0.4);
}
@media screen and (max-width: 480px) {
  .main-slider-bg--line {
    height: 242px;
  }
}
@media screen and (max-width: 640px) {
  .main-slider .slick-slide > div {
    display: flex;
    align-items: center;
    height: 100%;
    min-height: 320px;
  }
}
@media screen and (max-width: 480px) {
  .main-slider .slick-slide > div {
    min-height: 242px;
  }
}
.main-slider-wrapper {
  position: relative;
  z-index: 10;
}
.main-slider-block {
  padding-left: 65px;
  padding-right: 65px;
}
@media screen and (max-width: 768px) {
  .main-slider-block {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-slider-block .slick-arrow.slick-prev {
  left: 0;
  width: 20px;
}
.main-slider-block .slick-arrow.slick-prev:before {
  left: 0;
}
.main-slider-block .slick-arrow.slick-next {
  width: 20px;
  right: 0;
}
.main-slider-block .slick-arrow.slick-next:before {
  right: 0;
}
.main-slider-row {
  justify-content: space-between;
  flex-wrap: wrap;
}
.main-slider-col {
  display: flex;
  align-items: center;
  max-width: 410px;
  flex-basis: 0;
  flex-grow: 1;
}
@media screen and (max-width: 640px) {
  .main-slider-col {
    padding: 0;
  }
}
.main-slider-item {
  color: #ffffff;
  max-width: 365px;
}
.main-slider-title--title {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 100%;
}
@media screen and (max-width: 768px) {
  .main-slider-title--title {
    font-size: 3rem;
  }
}
@media screen and (max-width: 640px) {
  .main-slider-title--title {
    font-size: 1.8rem;
  }
}
.main-slider-desc {
  padding-top: 20px;
}
.main-slider-desc p {
  font-size: 1.4rem;
  line-height: 180%;
}
@media screen and (max-width: 640px) {
  .main-slider-desc p {
    font-size: 1.2rem;
    line-height: 130%;
  }
}
.main-slider-buttons {
  display: none;
}
@media screen and (max-width: 767px) {
  .main-slider-buttons {
    display: flex;
    flex: 0 0 100%;
    justify-content: center;
  }
}
.main-slider-btn {
  padding-top: 76px;
  padding-left: 5px;
  padding-bottom: 5px;
}
@media screen and (max-width: 768px) {
  .main-slider-btn {
    padding-top: 33px;
    width: 100%;
    max-width: 260px;
  }
}
@media screen and (max-width: 640px) {
  .main-slider-btn {
    padding-top: 23px;
  }
}
@media screen and (max-width: 767px) {
  .main-slider-btn-desk {
    display: none;
  }
}
.main-slider-btn .btn {
  font-size: 1.2rem;
}
@media screen and (max-width: 480px) {
  .main-slider-btn .btn {
    max-width: 250px;
  }
}
.main-slider-img {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 543px;
}
@media screen and (max-width: 640px) {
  .main-slider-img {
    padding: 0;
    display: flex;
    align-items: center;
  }
}
.who-are {
  padding-top: 100px;
  padding-bottom: 50px;
}
@media (max-width: 1024px) {
  .who-are {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 640px) {
  .who-are {
    padding-top: 70px;
  }
}
.who-are-content {
  padding-top: 50px;
}
.who-are-block {
  line-height: 180%;
}
.who-are-row {
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.15);
  padding: 30px 15px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
@media screen and (max-width: 767px) {
  .who-are-row {
    border-width: 1px;
    padding: 0 10px;
  }
}
.who-are-row:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #ffffff;
}
@media screen and (max-width: 767px) {
  .who-are-row:before {
    display: none;
  }
}
.who-are-row:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #ffffff;
}
@media screen and (max-width: 767px) {
  .who-are-row:after {
    display: none;
  }
}
.who-are-row > p {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  color: #ffffff;
  max-width: 50%;
  padding-top: 0;
}
@media screen and (max-width: 767px) {
  .who-are-row > p {
    padding: 20px 10px;
    max-width: 100%;
    position: relative;
  }
}
.who-are-row > p:last-of-type {
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
@media screen and (max-width: 767px) {
  .who-are-row > p:last-of-type {
    border: 0 none;
  }
  .who-are-row > p:last-of-type:before {
    content: "";
    position: absolute;
    left: 33%;
    top: 0;
    height: 1px;
    width: 122px;
    background-color: #ffffff;
  }
}

.advantages {
  padding-top: 153px;
}
@media screen and (max-width: 768px) {
  .advantages {
    padding-top: 103px;
  }
}
@media screen and (max-width: 640px) {
  .advantages {
    padding-top: 73px;
  }
}
.advantages-block {
  padding-top: 20px;
  outline: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .advantages-block {
    padding-top: 10px;
  }
}
.advantages-block:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #E5891C;
  display: block;
}
.advantages-content {
  padding-top: 50px;
}
.advantages-col {
  display: flex;
  align-items: center;
  flex: 0 0 25%;
  max-width: 25%;
}
@media screen and (max-width: 767px) {
  .advantages-col {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 4px 22px;
  }
}
.advantages-desc {
  height: 110px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (max-width: 1024px) {
  .advantages-desc {
    height: 95px;
  }
}
@media screen and (max-width: 768px) {
  .advantages-desc {
    height: 60px;
  }
}
@media screen and (max-width: 640px) {
  .advantages-desc {
    height: 55px;
  }
}
.advantages-desc--title p {
  font-size: 1.6rem;
  line-height: 180%;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .advantages-desc--title p {
    font-size: 1.2rem;
  }
}
@media (max-width: 370px) {
  .advantages-desc--title p {
    font-size: 1rem;
  }
}
.advantages-img {
  width: 100%;
  height: 32.41vh;
}
.advantages-img .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.our-clients {
  padding-top: 150px;
}
@media (max-width: 1024px) {
  .our-clients {
    padding-top: 100px;
  }
}
@media screen and (max-width: 640px) {
  .our-clients {
    padding-top: 73px;
  }
}
.our-clients-content {
  position: relative;
  padding: 55px 15px 0;
}
@media screen and (max-width: 768px) {
  .our-clients-content {
    padding-top: 30px;
  }
}
@media screen and (max-width: 640px) {
  .our-clients-content {
    padding: 55px 0 0;
  }
}
.our-clients-slider {
  width: 100%;
}
.our-clients-slider {
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
}
@media (max-width: 1024px) {
  .our-clients-slider {
    padding-left: 52px;
    padding-right: 52px;
  }
}
@media screen and (max-width: 768px) {
  .our-clients-slider {
    padding-left: 42px;
    padding-right: 42px;
  }
}
@media screen and (max-width: 640px) {
  .our-clients-slider {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.our-clients-slider .slick-arrow.slick-prev {
  left: 0;
  width: 20px;
}
.our-clients-slider .slick-arrow.slick-prev:before {
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: contain;
}
.our-clients-slider .slick-arrow.slick-next {
  width: 20px;
  right: 0;
}
.our-clients-slider .slick-arrow.slick-next:before {
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: contain;
}
.our-clients .slick-list {
  padding: 0;
}
.our-clients .slick-slide {
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
  .our-clients .slick-slide {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.our-clients .slick-slide > div {
  display: flex;
  justify-content: center;
}
.our-clients-slide {
  max-width: 200px;
  width: 100%;
  padding: 0;
}
.our-clients-col {
  display: flex;
  align-items: center;
}
.our-clients-item {
  color: #ffffff;
}
.our-clients-img {
  width: 100%;
  max-width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .our-clients-img {
    height: 90px;
  }
}
.our-clients-img img {
  object-fit: contain;
  height: 100%;
}
.our-clients-desc {
  padding-top: 20px;
  text-align: center;
}
.our-clients--text {
  font-size: 1.4rem;
  line-height: 130%;
}
@media (max-width: 1024px) {
  .our-clients--text {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 768px) {
  .our-clients--text {
    font-size: 1rem;
  }
}

.about {
  padding-top: 30px;
}
.about-banner {
  height: 37.04vh;
  overflow: hidden;
}
.about-banner .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-row {
  padding-top: 50px;
}
@media screen and (max-width: 767px) {
  .about-row {
    flex-wrap: wrap;
    padding-top: 0;
  }
}
.about-sidebar {
  max-width: 380px;
  flex: 0 0 33%;
}
@media screen and (max-width: 767px) {
  .about-sidebar {
    max-width: 100%;
    flex: 0 0 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
.about-sidebar-logo {
  border: 1px solid rgba(222, 222, 222, 0.2);
  position: relative;
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
}
.about-sidebar-logo:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #E5891C;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: all 0.3s ease-in-out;
}
.about-content {
  max-width: 760px;
  flex: 0 0 67%;
}
@media screen and (max-width: 767px) {
  .about-content {
    max-width: 100%;
    flex: 0 0 100%;
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}
.services {
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  .services-row {
    justify-content: center;
  }
}
.services-col {
  flex: 0 0 33.33%;
  max-width: 380px;
  padding-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .services-col {
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 640px) {
  .services-col {
    flex: 0 0 100%;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}
.services-item {
  padding: 20px;
  border: 1px solid rgba(222, 222, 222, 0.2);
  position: relative;
  transition: all 0.3s linear;
  min-height: 208px;
}
.services-item:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #E5891C;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: all 0.3s ease-in-out;
}
.services-item-title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 26px;
  color: #ffffff;
}
.services-item-desc {
  padding-top: 10px;
}
.services-item-desc > p {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.7);
  display: none;
}
.services-item-desc > p:first-of-type {
  display: block;
}
.services-item > ol, .services-item > ul, .services-item > span {
  display: none;
}
.services-item-link {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 0;
}
.services-item-link--link {
  color: #e5891c;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  display: inline-flex;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.services-item-link--link:after {
  margin-left: 10px;
  content: "";
  position: relative;
  background-image: url("/images/svg/arrow-yellow.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: contain;
  width: 14px;
  height: 100%;
  display: block;
  transition: all 0.3s linear;
}
.services-item:hover {
  background-color: #E5891C;
}
.services-item:hover .services-item-link--link {
  color: #ffffff;
}
.services-item:hover .services-item-link--link:after {
  background-image: url("/images/svg/arrow-white.svg");
}

.service {
  padding-top: 50px;
}
@media screen and (max-width: 767px) {
  .service-row {
    flex-wrap: wrap;
  }
}
.service-sidebar {
  max-width: 380px;
}
@media (max-width: 1024px) {
  .service-sidebar {
    max-width: 320px;
  }
}
@media screen and (max-width: 768px) {
  .service-sidebar {
    max-width: 240px;
  }
}
@media screen and (max-width: 767px) {
  .service-sidebar {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
.service-sidebar-list {
  padding-top: 20px;
}
.service-sidebar-li {
  font-size: 1.4rem;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.2);
  position: relative;
  margin-bottom: 2px;
}
.service-sidebar-li:hover, .service-sidebar-li.active {
  color: #E5891C;
}
.service-sidebar-li:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #E5891C;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}
.service-sidebar-link {
  padding: 10px 20px;
  display: block;
}
.service-sidebar-buttons {
  padding-top: 30px;
}
@media screen and (max-width: 767px) {
  .service-sidebar-buttons {
    display: none;
  }
}
.service-sidebar-buttons .btn-border-norm {
  max-width: 100%;
  font-size: 1.2rem;
  color: #E5891C;
  border: 1px solid #E5891C;
}
@media screen and (max-width: 767px) {
  .service-content {
    max-width: 100%;
    flex: 0 0 100%;
    padding-top: 30px;
  }
}
.service-content-desc {
  padding-top: 30px;
}
.service-content-desc ul, .service-content-desc ol {
  list-style: initial;
  list-style-position: inside;
  line-height: 1.3;
}
.service-content-desc ul li, .service-content-desc ol li {
  padding-top: 15px;
}
.service-content-buttons {
  padding-top: 30px;
  display: none;
}
@media screen and (max-width: 767px) {
  .service-content-buttons {
    display: block;
  }
}
.service-content-buttons .btn-border-norm {
  max-width: 100%;
  font-size: 1.2rem;
  color: #E5891C;
  border: 1px solid #E5891C;
}

.guarantee {
  padding-top: 150px;
}
@media (max-width: 1024px) {
  .guarantee {
    padding-top: 100px;
  }
}
@media screen and (max-width: 767px) {
  .guarantee {
    padding-top: 70px;
  }
}
.guarantee-row {
  padding-top: 50px;
}
@media screen and (max-width: 767px) {
  .guarantee-row {
    flex-wrap: wrap;
    padding-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .guarantee-col {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .guarantee-col:last-of-type {
    padding-top: 50px;
  }
}
.guarantee-title {
  display: flex;
}
.guarantee-title-img {
  width: 64px;
  height: 64px;
}
.guarantee-title-img .img {
  width: 100%;
}
.guarantee-title--title {
  padding-left: 30px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 27px;
  color: #E5891C;
  position: relative;
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
}
.guarantee-list {
  padding-top: 30px;
  max-width: 420px;
}
.guarantee-list-desc {
  color: #ffffff;
  font-size: 1.4rem;
  line-height: 18px;
}
.guarantee-list-li {
  color: #ffffff;
  font-size: 1.4rem;
  line-height: 18px;
  padding-bottom: 20px;
  position: relative;
  padding-left: 18px;
}
.guarantee-list-li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("/images/svg/arrow-list.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: contain;
  width: 6px;
  height: 10px;
  display: block;
}
.guarantee-list-li:last-of-type {
  padding-bottom: 0;
}

.gallery {
  padding-top: 70px;
}
.gallery-block {
  padding-top: 50px;
}
.gallery-slider {
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
}
@media (max-width: 1024px) {
  .gallery-slider {
    padding-left: 52px;
    padding-right: 52px;
  }
}
@media screen and (max-width: 768px) {
  .gallery-slider {
    padding-left: 42px;
    padding-right: 42px;
  }
}
@media screen and (max-width: 640px) {
  .gallery-slider {
    padding-left: 0;
    padding-right: 0;
  }
}
.gallery-slider .slick-arrow.slick-prev {
  left: 0;
  width: 20px;
}
.gallery-slider .slick-arrow.slick-prev:before {
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: contain;
}
.gallery-slider .slick-arrow.slick-next {
  width: 20px;
  right: 0;
}
.gallery-slider .slick-arrow.slick-next:before {
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-size: contain;
}
.gallery .slick-list {
  padding: 0;
}
.gallery .slick-slide {
  max-width: 330px;
  flex: 0 0 33.33%;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 768px) {
  .gallery .slick-slide {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.gallery-slide {
  flex: 0 0 33%;
  max-width: 330px;
  width: 100%;
  display: flex;
}
.gallery-item {
  width: 100%;
}
.gallery-img {
  width: 100%;
  height: 262px;
  overflow: hidden;
  background-color: rgba(245, 246, 251, 0.5);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
}

.news {
  padding-top: 30px;
}
@media screen and (max-width: 640px) {
  .news-row {
    justify-content: center;
  }
}
.news .posts-col {
  padding-bottom: 30px;
}
.news nav {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.news nav .pagination {
  display: flex;
}
.news nav .page-item .page-link {
  font-size: 1.4rem;
  color: #ffffff;
  line-height: 1;
  padding: 5px;
  border-radius: 2px;
  min-width: 24px;
  display: flex;
  justify-content: center;
}
.news nav .page-item:hover .page-link {
  background-color: #E5891C;
}
.news nav .page-item.active .page-link {
  background-color: #3F619D;
}
.news nav .page-item.disabled .page-link {
  opacity: 0.2;
}
.news nav .page-item.disabled:hover .page-link {
  background-color: transparent;
}

.posts {
  padding-top: 100px;
}
.posts-title--title {
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 19px;
}
.posts-col {
  flex: 0 0 33.33%;
  max-width: 380px;
  padding-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .posts-col {
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 640px) {
  .posts-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 640px) {
  .posts-col:last-of-type {
    padding-bottom: 0;
  }
}
.posts-row {
  padding-top: 20px;
}
@media screen and (max-width: 640px) {
  .posts-row {
    justify-content: center;
  }
}
.posts-item {
  border: 1px solid rgba(222, 222, 222, 0.2);
  position: relative;
  transition: all 0.3s linear;
}
.posts-item:before {
  content: "";
  display: block;
  position: absolute;
  background-color: #E5891C;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  transition: all 0.3s ease-in-out;
}
.posts-item-img {
  height: 18.52vh;
}
.posts-item-img .img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.posts-item-desc {
  padding: 20px;
  min-height: 115px;
}
.posts-item-title {
  min-height: 42px;
}
.posts-item-title--title {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 20.8px;
}
.posts-item-info {
  padding-top: 14px;
  display: flex;
  justify-content: space-between;
}
.posts-item-date--item {
  color: #ffffff;
  font-weight: 500;
  font-family: "Oswald", sans-serif;
  opacity: 0.5;
  font-size: 1.4rem;
}
.posts-item-link--link {
  font-family: "Oswald", sans-serif;
  color: #E5891C;
  font-weight: 500;
  text-transform: uppercase;
}

.post {
  padding-top: 50px;
}
.post-wrapper {
  max-width: 760px;
}
.post-date--desc {
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  font-size: 1.4rem;
}
.post-title {
  padding-top: 10px;
}
.post-title--title {
  font-size: 3rem;
  line-height: 35px;
}
.post-banner {
  padding-top: 30px;
}
.post-banner-img {
  height: 37.96vh;
  overflow: hidden;
}
.post-banner-img .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-content {
  padding-top: 30px;
}

.price {
  padding-top: 30px;
}
.price-list:not(.open) .price-head-title:before {
  transform: rotate(0);
  transition: transform 0.3s linear;
}
.price-head {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
}
.price-head-title {
  padding-top: 12px;
  padding-bottom: 12px;
  position: relative;
  display: inline-flex;
  width: 100%;
  padding-left: 5px;
  color: #ffffff;
}
.price-head-title:before {
  content: "";
  position: relative;
  background-image: url(/images/svg/arrow-price.svg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: contain;
  width: 6px;
  height: 10px;
  display: block;
  margin-right: 17px;
  transform: rotate(90deg);
  transition: all 0.3s linear;
}
.price-head-title:hover {
  color: #E5891C;
}
.price-head-title:hover:before {
  background-image: url(/images/svg/arrow-price-hov.svg);
}
.price-table {
  padding-left: 30px;
  font-size: 1.4rem;
  line-height: 30px;
  display: none;
}
@media screen and (max-width: 640px) {
  .price-table {
    padding-left: 0;
    font-size: 1.2rem;
    line-height: 15px;
  }
}
.price-table-head {
  color: #7B9BD4;
  display: flex;
  height: 36px;
  align-items: center;
}
.price-table-head .price-table-text {
  padding-left: 0;
}
.price-table-row {
  display: flex;
}
@media screen and (max-width: 640px) {
  .price-table-row {
    padding: 15px 0;
  }
}
.price-table-row:nth-of-type(2n+1) {
  background-color: rgba(255, 255, 255, 0.05);
}
.price-table-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
}
.price-table-text {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 675px;
  flex: 0 0 60%;
}
@media screen and (max-width: 640px) {
  .price-table-text {
    padding-left: 15px;
    padding-right: 5px;
    flex: 0 0 50%;
  }
}
@media screen and (max-width: 640px) {
  .price-table-terms {
    flex: 0 0 25%;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.price-table-price {
  max-width: 240px;
  padding-right: 20px;
  justify-content: flex-end;
  text-align: right;
}
@media screen and (max-width: 640px) {
  .price-table-price {
    padding-left: 5px;
    padding-right: 15px;
    flex: 0 0 25%;
  }
}

.contact {
  padding-top: 30px;
}
.contact-map {
  position: relative;
}
.contact-map .map-block {
  width: 100%;
  height: 414px;
  background-color: grey;
}
.contact-map .map-info {
  position: absolute;
  top: 50px;
  z-index: 10;
  left: 0;
  height: 0;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .contact-map .map-info {
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    background-color: #ffffff;
  }
}
.contact-map .map-info-wrap {
  max-width: 1140px;
  height: 0;
  margin: 0 auto;
}
@media screen and (max-width: 640px) {
  .contact-map .map-info-wrap {
    height: 100%;
  }
}
.contact-map .map-info-block {
  max-width: 375px;
  width: 100%;
}
.contact-map .map-info-row {
  display: flex;
  flex-wrap: nowrap;
}
.contact-map .map-info-row:last-of-type .map-info-right:after {
  display: none;
}
.contact-map .map-info-left {
  flex: 0 0 84px;
  background-color: #E5891C;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 104px;
}
.contact-map .map-info-right {
  height: 104px;
  padding-left: 30px;
  flex: 1;
  background-color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 370px) {
  .contact-map .map-info-right {
    padding-left: 10px;
  }
}
.contact-map .map-info-right:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 30px;
  height: 1px;
  width: 100px;
  display: block;
  background-color: rgba(29, 47, 79, 0.1);
}
.contact-map .map-info-icon {
  width: 24px;
  height: 24px;
  display: block;
  background-image: url("/images/svg/contact-anchor.svg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: contain;
}
.contact-map .map-info-anchor {
  background-image: url("/images/svg/contact-anchor.svg");
}
.contact-map .map-info-phone {
  background-image: url("/images/svg/contact-phone.svg");
}
.contact-map .map-info-email {
  background-image: url("/images/svg/contact-email.svg");
}
.contact-map .map-info-cont {
  color: #1D2F4F;
  font-size: 1.4rem;
  line-height: 25px;
}
.contact-map .map-info-link {
  color: #1D2F4F;
  font-size: 1.4rem;
  display: block;
  line-height: 25px;
}