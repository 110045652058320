.footer {
  margin-top: 100px;
  border-top: 1px solid rgba($color-gray-3, 0.15);
  position: relative;
  overflow: hidden;
  @include phone{
    margin-top: 70px;
  }
  &-wrapper {
    padding-top: 50px;
    padding-bottom: 70px;
    font-size: 1.6rem;
    color: $color-white;
    @include phone{
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 30px;
    }
  }

  &-row {
    display: flex;
    flex-wrap: nowrap;
  }

  &-left {
    flex: 0 0 50%;
    max-width: 50%;
    @include tablet {
      flex: 0 0 30%;
      max-width: 30%;
    }
    @include portrait-tablet {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-block {

    }

    &-logo {
      &-link {

      }

      &--img {

      }
    }

    &-title {
      font-size: 1.4rem;
      font-family: $font-osw;
      font-weight: $font-osw-bold;
      line-height: 21px;
      padding-top: 13px;
      text-transform: uppercase;
      @include desktop-block {
        font-size: 1.2rem;
        line-height: 18px;
      }
      @include tablet {

      }
      @include phone {
        font-size: 1rem;
        line-height: 15px;
      }

      &--text {
        position: relative;
        padding-right: 11px;
        @include phone{
          padding-top: 4px;
        }
        span {
          z-index: 10;
          position: relative;
          color: $color-blue-1;
        }

        &:before {
          z-index: 0;
          position: absolute;
          right: 0;
          top: 0;
          content: '';
          width: 1000px;
          height: 23px;
          background-color: $color-blue-2;
          border-radius: 0 11.5px 11.5px 0;
        }

        //&:after{
        //    z-index: 0;
        //    position: absolute;
        //    left: 0;
        //    top: 0;
        //    content: '';
        //    border-radius: 0 11.5px 11.5px 0;
        //    width: 100%;
        //    height: 23px;
        //    background-color: $color-blue-2;
        //
        //}
      }
    }

    &-copyright {
      padding-top: 65px;
      font-size: 1.4rem;
      line-height: 180%;
      text-transform: uppercase;
      @include phone {
        font-size: 1rem;
      }
    }

  }

  &-right {
    flex: 0 0 50%;
    max-width: 50%;
    @include tablet {
      flex: 0 0 70%;
      max-width: 70%;
    }
    @include portrait-tablet {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-block {
      display: flex;
      flex-wrap: nowrap;
    }

    &-item {
      flex: 0 0 50%;
      max-width: 50%;
      @include portrait-tablet {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &:first-of-type {
        @include portrait-tablet {
          display: none;
        }
      }
    }

    &-title {
      &--text {
        font-family: $font-osw;
        font-weight: $font-osw-medium;
        font-size: 2.4rem;
        line-height: 128%;
        padding-bottom: 10px;
        position: relative;

        &:after {
          content: '';
          background-color: $color-yellow;
          height: 1px;
          width: 58px;
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
        }
      }
    }

    &-lists {
      padding-top: 20px;
    }

    &-list {
      flex: 0 0 auto;
      @include tablet {
        flex: 0 0 33.33%;
      }
    }

    &-li {
      font-size: 1.6rem;
      padding-top: 15px;
      @include tablet {
        padding-top: 20px;
        font-size: 1.6rem;
        line-height: 1.9rem;
      }
      @include desktop-normal {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }

      &:first-of-type {
        padding-top: 0;
      }
    }

    &-phons {

    }

    &-phone {
      display: block;
      font-size: 1.8rem;
      @include phone{
        font-size: 1.4rem;
      }
      &:last-of-type {
        padding-top: 9px;
      }
    }

    &-address {
      padding-top: 17px;
      font-size: 1.4rem;
    }

    &-social {
      padding-top: 20px;
      display: flex;
      justify-content: left;

      &-item {
        height: 30px;
        padding-right: 10px;
        padding-top: 3px;

        &:last-of-type {
          padding-left: 10px;
          border-left: 1px solid rgba($color-white, 0.15);
        }
      }

      &-link {
        width: 24px;
        height: 24px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-attachment: scroll;

        &.facebook {
          background-image: url('/images/svg/facebook.svg');

          &:hover {
            background-image: url('/images/svg/facebook-hover.svg');
          }
        }

        &.instagram {
          background-image: url('/images/svg/instagram.svg');

          &:hover {
            background-image: url('/images/svg/instagram-hover.svg');
          }
        }
      }
    }
  }

  &-bottom {
    padding-top: 10px;
    padding-bottom: 10px;

    //background-color: $color-gray-4;
    //font-size: 0.9rem;
    //line-height: 125%;
    //opacity: 0.6;
    //@include extra-phone-big{
    //  font-size: 1rem;
    //}
    //@include tablet {
    //  padding-top: 22px;
    //  padding-bottom: 22px;
    //  font-size: 1.4rem;
    //  line-height: 1.7rem;
    //}

    &-wrapper {
      border-top: 3px solid $color-green;
    }

    &-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      @include tablet {
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    &-col {
      width: 100%;
      text-align: center;
    }

    &-desc {
      padding-top: 40px;
      font-size: 1.6rem;
    }

    &-copyright {
      padding-top: 20px;
      color: $color-green;
      font-size: 1.6rem;
    }


  }
}
