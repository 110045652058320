.p-home{
  padding-top: 310px;
  @include portrait-tablet{
    padding-top: 210px;
  }
  @include phone{
    padding-top: 170px;
  }
}
.main-slider {
  padding-top: 37px;
  @include portrait-tablet{
    padding-top: 0;
  }
  &-container {
    position: relative;
  }

  &-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 0;

    &--line {
      height: 318px;
      width: 100%;
      background-color: rgba($color-blue-3, 0.4);
      @include little-phone{
        height: 242px;
      }
    }
  }
  .slick-slide{
    &>div{
      @include phone {
        display: flex;
        align-items: center;
        height: 100%;
        min-height: 320px;
      }
      @include little-phone{
        min-height: 242px;
      }
    }
  }
  &-wrapper {
    position: relative;
    z-index: 10;
    //padding: 0;
  }

  &-block {
    padding-left: 65px;
    padding-right: 65px;
    @include tablet{
      padding-left: 0;
      padding-right: 0;
    }
    .slick-arrow.slick-prev{
      left: 0;
      width: 20px;
      &:before{
        left: 0;
      }
    }
    .slick-arrow.slick-next{
      width: 20px;
      right: 0;
      &:before{
        right: 0;
      }
    }
  }

  &-slide {

  }
  & .slick-dots{
    @include tablet{

    }
  }

  &-row {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-col {
    display: flex;
    align-items: center;
    max-width: 410px;
    flex-basis: 0;
    flex-grow: 1;
    @include phone{
      padding: 0;
    }
  }

  &-item {
    color: $color-white;
    max-width: 365px;
  }

  &-title {
    &--title {
      font-family: $font-osw;
      font-weight: $font-osw-bold;
      font-size: 3.6rem;
      line-height: 100%;
      @include tablet{
        font-size: 3rem;
      }
      @include phone{
        font-size: 1.8rem;
      }
    }
  }

  &-desc {
    padding-top: 20px;
    p{
      font-size: 1.4rem;
      line-height: 180%;
      @include phone{
        font-size: 1.2rem;
        line-height: 130%;
      }
    }

  }

  &-buttons{
    display: none;
    @include portrait-tablet{
      display: flex;
      flex: 0 0 100%;
      justify-content: center;
    }
  }

  &-btn {
    padding-top: 76px;
    padding-left: 5px;
    padding-bottom: 5px;
    @include tablet{
      padding-top: 33px;
      width: 100%;
      max-width: 260px;
    }
    @include phone{
      padding-top: 23px;
    }
    &-desk{
      @include portrait-tablet{
        display: none;
      }
    }
    .btn {
      font-size: 1.2rem;
      @include little-phone{
        max-width: 250px;
      }
    }
  }

  &-img {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 543px;
    @include phone{
      padding: 0;
      display: flex;
      align-items: center;
    }
    &-item {

    }
  }
}

.who-are {
  padding-top: 100px;
  padding-bottom: 50px;
  @include desktop-block{
    padding-bottom: 0;
  }
  @include phone{
    padding-top: 70px;
  }
  &-container {

  }

  &-content {
    padding-top: 50px;
  }

  &-wrapper {

  }

  &-block {
    line-height: 180%;
  }

  &-row {
    //border: 1px solid $color-white;
    border-width: 1px 0 1px 0 ;
    border-style: solid;
    border-color: rgba($color-white,0.15);
    padding: 30px 15px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @include portrait-tablet{
      border-width: 1px;
      padding: 0 10px;
    }
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: $color-white;
      @include portrait-tablet{
        display: none;
      }
    }
    &:after{
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: $color-white;
      @include portrait-tablet{
        display: none;
      }
    }
    &>p{
      display: flex;
      //align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      color: $color-white;
      //max-width: 365px;
      max-width: 50%;
      padding-top: 0;
      @include portrait-tablet{
        padding: 20px 10px;
        max-width: 100%;
        position: relative;
      }
      &:last-of-type{
        border-left: 1px solid rgba($color-white,0.15);
        @include portrait-tablet{
          border: 0 none;
          &:before{
            content: '';
            position: absolute;
            left: 33%;
            top: 0;
            height: 1px;
            width: 122px;
            background-color: $color-white;
          }
        }
      }
    }
  }

}

.advantages {
  padding-top: 153px;
  @include tablet{
    padding-top: 103px;
  }
  @include phone{
    padding-top: 73px;
  }

  &-block {
    padding-top: 20px;
    //padding-bottom: 37px;
    outline: 1px solid rgba($color-white,0.15);
    position: relative;
    width: 100%;
    @include tablet{
      padding-top: 10px;
    }
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-yellow;
      display: block;
    }
  }

  &-content{
    padding-top: 50px;
  }

  &-col {
    display: flex;
    align-items: center;
    flex: 0 0 25%;
    max-width: 25%;
    @include portrait-tablet{
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 4px 22px;
    }
  }

  &-desc {
    height: 110px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include desktop-block{
      height: 95px;
    }
    @include tablet{
      height: 60px;
    }
    @include phone{
      height: 55px;
    }
    &--title {
      p{
        font-size: 1.6rem;
        line-height: 180%;
        text-transform: uppercase;
        @include menu-tablet{
          font-size: 1.2rem;
        }
        @include extra-phone-single{
          font-size: 1rem;
        }
      }

    }
  }


  &-img {
    width: 100%;
    height: pxVh(350,1080);
    & .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.our-clients {
  padding-top: 150px;
  @include desktop-block{
    padding-top: 100px;
  }
  @include phone{
    padding-top: 73px;
  }

  &-wrapper {

  }
  &-content{
    position: relative;
    padding: 55px 15px 0;
    @include tablet{
      padding-top: 30px;
    }
    @include phone{
      padding: 55px 0 0;
    }
  }
  &-slider {
    width: 100%;
  }
  &-slider{
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    @include desktop-block{
      padding-left: 52px;
      padding-right: 52px;
    }
    @include tablet{
      padding-left: 42px;
      padding-right: 42px;
    }
    @include phone{
      padding-left: 20px;
      padding-right: 20px;
    }
    .slick-arrow.slick-prev{
      left: 0;
      width: 20px;

      &:before{
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        background-size: contain;
      }
    }
    .slick-arrow.slick-next{
      width: 20px;
      right: 0;
      &:before{
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        background-size: contain;
      }
    }
  }
  & .slick-list{
    padding: 0;
  }
  & .slick-slide{
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
    @include tablet{
      padding-left: 10px;
      padding-right: 10px;
    }
    &>div{
      display: flex;
      justify-content: center;
    }
  }
  &-slide{
    max-width: 200px;
    width: 100%;
    padding: 0;

  }


  &-col {
    display: flex;
    align-items: center;
  }

  &-item {
    color: $color-white;
  }
  &-img {
    width: 100%;
    max-width: 100%;
    //height: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @include phone{
      height: 90px;
    }

    img {
      object-fit: contain;
      //width: 100%;
      height: 100%;
    }
  }

  &-desc {
    padding-top: 20px;
    text-align: center;
  }
  &--text{
    font-size: 1.4rem;
    line-height: 130%;
    @include desktop-block{
      font-size: 1.2rem;
    }
    @include tablet{
      font-size: 1rem;
    }
  }

}
