body {
  &.show-modal{
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    -webkit-overflow-scrolling: touch;
    background:$color-blue-2;
    .modal{
      display: flex;
    }
    .bg-overlay{
      display: block;
    }
  }

}
.modal{
  display: none;
  justify-content: center;
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  height: 100vh;
  width: 100%;
  background-color: rgba($color-black,0.5);
  align-items: center;
  &-block{
    position: relative;
    width: 100%;
    max-width: 600px;
    background-color: $color-yellow;
    height: 300px;
    padding: 20px;
    display: flex;
    justify-content: center;
    z-index: 600;
    flex-wrap: wrap;
    @include tablet{
      max-width: 500px;
      height: 260px;
    }
    @include phone{
      max-width: 320px;
      height: 230px;
    }
    .close-block{
      .close{
        font-size: 1.6rem;
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: 0 none;
        cursor: url(/images/icons/cursor-hover.svg), pointer;
        min-width: 20px;
        width: 20px;
        height: 20px;
      }
    }
  }
  &-close{
    width: 23px;
    height: 23px;
    position: absolute;
    top: 0;
    right: 0;
    background-image:  url(/images/svg/close.svg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: contain;
  }
  &-text{
    color: $color-white;
  }
  &-content{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-title{
     text-align: center;
      font-size: 3.3rem;
      font-family: $font-osw;
    text-transform: uppercase;
    @include tablet{
      font-size: 2.4rem;
    }
  }
  &-desc{
    padding-top: 10px;
    text-align: center;
    font-size: 1.4rem;
    @include tablet{
      font-size: 1.2rem;
    }
  }
}
.bg-overlay{
  position: fixed;
  display: none;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  z-index: 500;
  top: 0; left: 0; right: 0; bottom: 0;
  cursor: pointer;
}
