.site-width {
  //width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  @include desktop-block {
    max-width: 960px;
  }
  @include tablet{
    //max-width: 730px;
  }
  @include phone {
    //padding-right: 10px;
    //padding-left: 10px;
    padding-right: 12px;
    padding-left: 12px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  @include tablet {
    margin-right: 0;
    margin-left: 0;
  }
  //@include phone {
  //  margin-right: 0;
  //  margin-left: 0;
  //}

}

.col {
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  @include tablet{
    padding-right: 10px;
    padding-left: 10px;
  }

}
