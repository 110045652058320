.header {
  will-change: transform, opacity;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  min-width: 320px;
  z-index: 800;
  position: absolute;
  background-color: transparent;
  transition: background-color 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);

  &-wrapper {
    position: relative;
  }

  &-site-width {
    position: relative;
    z-index: 5;
  }

  &-row {
    position: relative;
    @include phone {
      justify-content: center;
    }
  }

  &-logo {
    display: block;
    flex: 0 0 30%;
    padding-top: 30px;

    &-link {
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      max-width: 170px;
      @include portrait-tablet {
        flex-wrap: nowrap;
      }
    }

    &-icon {
      max-width: 170px;
      flex: 0 0 100%;
    }

    &-text {
      padding-top: 10px;
      flex: 0 0 100%;

      &--text {
        display: none;
      }
    }

    & .img {
      object-fit: contain;
    }
  }

  &-lang {
    position: relative;
    padding-left: 5px;
    padding-right: 10px;
    height: 30px;
    align-items: center;
    display: flex;
    border-right: 1px solid rgba($color-white, 0.15);
    @include phone {
      padding-left: 0;
      padding-right: 0;
      height: auto;
      order: 1;
      position: absolute;
      right: 0;
      top: 0;
      padding-top: 10px;
      border-right: 0 none;
    }

    &:hover {
      .header-lang-modal {
        display: block;
      }
    }

    &-active {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      text-transform: uppercase;
      @include phone {
        border: 1px solid rgba($color-white, 0.1);
        height: 48px;
        width: 48px;
        justify-content: center;
        line-height: 1;

      }

      &:after {
        content: '';
        position: relative;
        background-image: url('/images/svg/arrow-bot.svg');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        width: 8px;
        height: 4px;
        display: block;
        margin-left: 5px;
        transition: all 0.3s linear;
        @include phone {
          display: none;
        }
      }
    }

    &-btn {

    }

    &-modal {
      position: absolute;
      left: 0;
      top: 25px;
      width: 100%;
      max-width: 47px;
      background-color: $color-white;
      display: none;
      @include phone {
        top: 58px;
        left: 0;
      }
    }

    &-btn {
      color: $color-blue-1;
      padding: 10px;
      border: 0 none;
      font-size: 1.4rem;
      text-transform: uppercase;
      height: 36px;

      &:hover {
        background-color: rgba($color-blue-1, 0.15);
      }

      &.active {
        color: $color-yellow;
      }
    }
  }

  &-right {
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
    flex: 0 0 70%;
    @include portrait-tablet {
      padding-top: 14px;
      z-index: 500;
    }
    @include phone {
      width: auto;
      flex: 0 0 auto;
      padding: 10px 0 0;
    }

    &-block {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
    }
  }

  &-menu {
    order: 3;
    padding-top: 50px;
    //@include portrait-tablet {
    //  padding-top: 0;
    //}

    &-block {
      display: flex;
      @include portrait-tablet {
        display: none;
      }

    }

    &-before {
      position: relative;
      padding-right: 11px;

      span {
        z-index: 10;
        position: relative;
        color: $color-white;
        font-family: $font-osw;
        font-weight: $font-osw-bold;
        font-size: 1.4rem;
        height: 30px;
        display: flex;
        align-items: center;
      }

      &:before {
        z-index: 0;
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        width: 1000px;
        height: 30px;
        background-color: $color-yellow;
        border-radius: 0 11.5px 11.5px 0;
      }

      @include portrait-tablet {
        display: none;
      }
    }

    &-nav {
      @include portrait-tablet {
        padding: 70px 52px 70px;
        overflow-y: auto;
        height: 100%;
      }

      & .nav {
        &-ul {
          display: flex;
          @include portrait-tablet {
            display: block;
            max-width: 350px;
          }

          &-sub {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 425px;
            visibility: hidden;
            opacity: 0;
            background-color: $color-blue-4;
            //margin-top: 30px;
            margin-top: 34px;
            z-index: 0;
            transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
            outline: 1px solid $color-blue-7;
            box-shadow: 0 8px 10px rgba($color-blue-2, 0.15);
            @include tablet{
              width: 410px;

            }
            @include portrait-tablet {
              transition: none;
              width: 100%;
              position: relative;
              background-color: transparent;
              margin-top: 0;
              visibility: visible;
              opacity: 1;
              height: 100%;
              box-shadow: none;
              display: none;
              outline: none;
            }
          }
        }

        &-li {
          padding-left: 20px;
          padding-right: 20px;
          position: relative;
          @include portrait-tablet {
            padding: 30px 0 0;
            &:first-of-type {
              padding-top: 0;
            }
          }
          @include tablet-hover {
            &:hover {
              .nav-ul-sub {
                display: block;
                visibility: visible;
                height: auto;
                opacity: 1;
              }
            }
          }

          &-sub {
            padding-left: 0;
            padding-right: 0;
            @include tablet{
              padding-top: 0;
            }
            //@include portrait-tablet {
            //  padding-top: 20px;
            //}
          }
        }

        &-link {
          position: relative;
          font-family: $font-osw;
          font-weight: $font-osw-medium;
          font-size: 1.8rem;
          line-height: 27px;
          z-index: 5;
          color: $color-white;
          transition: color 0.3s linear;
          @include portrait-tablet {
            font-size: 2.4rem;
          }

          &:before {
            content: '';
            height: 1px;
            width: 0;
            position: absolute;
            left: 0;
            bottom: -3px;
            background-color: $color-yellow;
            transition: all 0.2s linear;
            @include portrait-tablet {
              display: none;
            }
          }

          &.active {
            color: $color-yellow;
          }

          &.nav-link-arrow {
            display: flex;
            align-items: center;
            @include portrait-tablet {
              display: inline-flex;
            }

            &:after {
              content: '';
              height: 9px;
              width: 10px;
              margin-left: 7px;
              background-image: url('/images/svg/arrow-bot.svg');
              background-repeat: no-repeat;
              background-attachment: scroll;
              background-size: contain;
              background-position: center center;
              transform: rotate(0);
              transition: all 0.3s linear;
            }

            @include tablet-hover {
              &:hover {
                &:after {
                  transform: rotate(180deg);
                  background-image: url("/images/svg/arrow-bot-hover.svg");
                }
              }
            }

            &.open {
              color: $color-yellow;

              &:after {
                transform: rotate(180deg);
                background-image: url("/images/svg/arrow-bot-hover.svg");
              }
            }
          }

          @include tablet-hover {
            &:hover {
              color: $color-yellow;

              &:before {
                content: '';
                height: 1px;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: -3px;
                background-color: $color-yellow;
                @include portrait-tablet {
                  display: none;
                }
              }
            }
          }

          &-sub {
            font-weight: $font-regular;
            font-size: 1.4rem;
            font-family: $font-roboto;
            padding: 10px 20px;
            display: block;

            &:hover {
              @include tablet-hover {
                background-color: $color-blue-6
              }
            }

            &.active {
              color: $color-yellow;
              font-weight: $font-bold;
            }

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  &-mobile {
    display: none;
    transition: all 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    padding-top: 70px;
    background: $gradient-blue;
    right: 0;
    height: 100%;
    min-height: 100vh;
    z-index: 1000;
    transform: translateX(-120%);
    @include tablet {
      display: block;
    }

    &.menu-opened {
      @include tablet {
        transform: translateX(0);
      }
    }
    &-nav{
      padding: 70px 0;
      overflow-y: auto;
      height: 100%;
    }
    & .nav {
      &-ul {
        display: block;
        padding: 0 52px;
        @include phone{
          padding: 0 30px;
        }
        &-sub {
          transition: none;
          width: 100%;
          position: relative;
          background-color: transparent;
          visibility: visible;
          opacity: 1;
          height: 100%;
          box-shadow: none;
          display: none;
          outline: none;
          padding: 0;
        }
      }

      &-li {
          padding: 30px 0 0;
        &-sub {
          padding: 10px 0 ;
          &:first-of-type{
            padding: 22px 0 10px;
          }
        }
      }

      &-link {
        position: relative;
        font-family: $font-osw;
        font-weight: $font-osw-medium;
        line-height: 27px;
        z-index: 5;
        color: $color-white;
        transition: color 0.3s linear;
        font-size: 2.4rem;
        &.active {
          color: $color-yellow;
        }

        &.nav-link-arrow {
          display: inline-flex;
          align-items: center;
          &:after {
            content: '';
            height: 9px;
            width: 10px;
            margin-left: 7px;
            background-image: url("/images/svg/arrow-bot.svg");
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-size: contain;
            background-position: center center;
            transform: rotate(0);
            transition: all 0.3s linear;
          }

          &.open {
            color: $color-yellow;

            &:after {
              transform: rotate(180deg);
              background-image: url("/images/svg/arrow-bot-hover.svg");
            }
          }
        }

        &-sub {
          font-weight: $font-regular;
          font-size: 1.4rem;
          font-family: $font-roboto;
          display: block;
          padding: 0;
          line-height: 110%;
          &.active {
            color: $color-yellow;
            font-weight: $font-bold;
          }

         }
      }
    }
  }

  &-burger {
    padding-top: 10px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1200;
    display: none;
    @include portrait-tablet {
      display: block;
    }

    & .burger {
      background-color: rgba($color-white, 0.1);
      color: #ccc;
      display: block;
      position: relative;
      overflow: hidden;
      margin: 0;
      padding: 0;
      width: 48px;
      height: 48px;
      font-size: 0;
      text-indent: -9999px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      box-shadow: none;
      border: none;
      border-radius: 0;
      cursor: pointer;
      -webkit-transition: background 0.3s;
      transition: background 0.3s;

      &:focus {
        outline: none;
      }

      & span {
        display: block;
        position: absolute;
        top: 25px;
        left: 16px;
        right: 16px;
        height: 1px;
        background: $color-white;
      }

      & span::before,
      & span::after {
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-white;
        content: "";
      }

      & span::before {
        top: -5px;
      }

      & span::after {
        bottom: -5px;
      }

      &.burger-left {
        span {
          background-color: $color-white;
          transition: transform 0.3s;

          &:before,
          &:after {
            background-color: $color-white;
          }

          &:before {
            transform-origin: top right;
            transition: transform 0.3s, width 0.3s, top 0.3s;
          }

          &:after {
            transform-origin: bottom right;
            transition: transform 0.3s, width 0.3s, bottom 0.3s;
          }
        }

        /* when menu open: */
        &.is-active span {
          background-color: $color-white;
          transform: rotate(180deg);

          &:before,
          &:after {
            background-color: $color-white;
            width: 50%;
          }

          &:before {
            top: 0;
            transform: translateX(8px) translateY(0px) rotate(45deg);
          }

          &:after {
            bottom: 0;
            transform: translateX(8px) translateY(-0px) rotate(-45deg);
          }
        }
      }
    }
  }

  &.home {
    padding-bottom: 0;

    @include portrait-tablet {
      max-width: 100%;
    }

    .header {

      &-wrapper {
        &:after {
          position: absolute;
          left: 0;
          top: 0;
          content: '';
          width: 100%;
          max-width: 100vw;
          height: 318px;
          background: $gradient-yellow;
          display: block;
          z-index: 0;
          @include tablet {
            height: 210px;
          }
          @include portrait-tablet {
            height: 170px;
            max-width: 100%;
          }
          //@include little-phone {
          //  height: 170px;
          //}
        }
      }

      &-social {
        display: flex;
        justify-content: left;
        border-left: 1px solid rgba($color-white, 0.15);
        padding-left: 5px;
        @include phone {
          display: none;
        }

        &-item {
          height: 30px;
          padding-right: 5px;
          padding-top: 3px;
          padding-left: 5px;
        }

        &-link {
          width: 24px;
          height: 24px;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: scroll;

          &.facebook {
            background-image: url(/images/svg/facebook.svg);

            &:hover {
              opacity: 0.5;
            }
          }

          &.instagram {
            background-image: url(/images/svg/instagram.svg);

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }

      &-right {
        flex: 0 0 100%;
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        @include portrait-tablet {
          padding-top: 14px;
          z-index: 1100;
        }
        @include phone {
          padding-left: 0;
          padding-right: 0;
          padding-top: 10px;
          justify-content: center;
          height: 58px;
        }

        &-block {
          display: flex;
          justify-content: flex-end;
          align-items: center;

        }
      }

      &-logo {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
        padding-top: 56px;
        @include phone {
          padding-top: 33px;
        }

        &-link {
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 100%;
          transition: none;
          @include portrait-tablet {
            max-width: 300px;
            margin: 0 auto;
          }
        }

        &-icon {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 170px;
          @include portrait-tablet {
            flex: 0 0 33%;
            max-width: 33%;
          }
        }

        &-text {
          flex-basis: 0;
          flex-grow: 1;
          max-width: 625px;
          margin-left: 63px;
          @include portrait-tablet {
            margin-left: 20px;
            max-width: 77%;
          }

          img {
            @include portrait-tablet {
              max-width: 100%;
            }
          }

          &--text {
            display: none;
            @include portrait-tablet {
              color: $color-yellow;
              font-weight: $font-osw-bold;
              font-family: $font-osw;
              letter-spacing: 0.27em;
              line-height: 18px;
              display: block;
              text-transform: uppercase;
              font-size: 1rem;
            }
          }
        }

        & .img {
          object-fit: contain;
        }
      }

      &-phones {
        position: relative;
        height: 30px;
        align-items: center;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        @include phone {
          border-left: 0 none;
        }

        &:hover {
          .header-phones-modal {
            opacity: 1;
            visibility: visible
          }
        }

        &-active {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          font-family: $font-osw;
          font-weight: $font-osw-bold;
          font-size: 1.6rem;
          letter-spacing: 1px;

          &:after {
            content: '';
            position: relative;
            background-image: url('/images/svg/arrow-bot.svg');
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center;
            width: 8px;
            height: 4px;
            display: block;
            margin-left: 5px;
            transition: all 0.3s linear;
          }
        }

        &-modal {
          position: absolute;
          left: 0;
          top: 25px;
          width: 233px;
          background-color: $color-white;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s linear, visibility 0.3s linear;
          @include desktop-little {
            left: auto;
            right: 0;
          }
        }

        &-block {
          padding: 20px;
          display: flex;
          flex-wrap: wrap;

        }

        &-item {
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 10px;

          &.center {
            text-align: center;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }

        &-phone {
          color: $color-blue-1;
          font-family: $font-osw;
          font-weight: $font-osw-bold;
          font-size: 1.6rem;
          line-height: 24px;
          letter-spacing: 1px;
        }

        &-time {
          border-top: 1px solid rgba($color-blue-1, 0.15);
          padding: 10px 20px;
          color: $color-blue-1;
          font-weight: $font-bold;
          display: flex;
          justify-content: center;

          &-item {
            flex: 1;
            height: 27px;
            display: flex;
            align-items: center;

            &:last-of-type {
              border-left: 1px solid rgba($color-blue-1, 0.15);
              padding-left: 20px;
            }
          }
        }
      }

    }
  }

  &.not-home {
    .header {
      &-row {
        @include portrait-tablet {
          height: 58px;
          justify-content: center;
        }
      }

      &-phones {
        position: relative;
        height: 30px;
        align-items: center;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        @include phone {
          display: none;
        }

        &:hover {
          .header-phones-modal {
            opacity: 1;
            visibility: visible;
          }
        }

        &-active {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          font-family: $font-osw;
          font-weight: $font-osw-bold;
          font-size: 1.6rem;
          letter-spacing: 1px;

          &:after {
            content: '';
            position: relative;
            background-image: url('/images/svg/arrow-bot.svg');
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center;
            width: 8px;
            height: 4px;
            display: block;
            margin-left: 5px;
            transition: all 0.3s linear;
          }
        }

        &-modal {
          position: absolute;
          left: 0;
          top: 25px;
          width: 233px;
          background-color: $color-white;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s linear, visibility 0.3s linear;
          @include desktop-little {
            left: auto;
            right: 0;
          }
        }

        &-block {
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
        }

        &-item {
          flex: 0 0 100%;
          max-width: 100%;
          padding-bottom: 10px;

          &.center {
            text-align: center;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }

        &-phone {
          color: $color-blue-1;
          font-family: $font-osw;
          font-weight: $font-osw-bold;
          font-size: 1.6rem;
          line-height: 24px;
          letter-spacing: 1px;
        }

        &-time {
          border-top: 1px solid rgba($color-blue-1, 0.15);
          padding: 10px 20px;
          color: $color-blue-1;
          font-weight: $font-bold;
          display: flex;
          justify-content: center;

          &-item {
            flex: 1;
            height: 27px;
            display: flex;
            align-items: center;

            &:last-of-type {
              border-left: 1px solid rgba($color-blue-1, 0.15);
              padding-left: 20px;
            }
          }
        }
      }

      &-social {
        display: flex;
        justify-content: left;
        border-left: 1px solid rgba($color-white, 0.15);
        padding-left: 5px;
        @include phone {
          display: none;
        }

        &-item {
          height: 30px;
          padding-right: 5px;
          padding-top: 3px;
          padding-left: 5px;
        }

        &-link {
          width: 24px;
          height: 24px;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          background-attachment: scroll;

          &.facebook {
            background-image: url(/images/svg/facebook.svg);

            &:hover {
              background-image: url(/images/svg/facebook-hover.svg);
            }
          }

          &.instagram {
            background-image: url(/images/svg/instagram.svg);

            &:hover {
              background-image: url(/images/svg/instagram-hover.svg);
            }
          }
        }
      }

      &-logo {
        display: block;
        flex: 0 0 30%;
        padding-top: 30px;
        @include portrait-tablet {
          display: none;
        }
        @include phone {
          padding-top: 10px;
          justify-content: center;
          display: flex;
          flex: 0 0 100%;
          height: 58px;
          z-index: 1100;
        }

        &-link {
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          max-width: 170px;
          transition: none;
          @include portrait-tablet {
            flex-wrap: nowrap;
          }
        }

        &-icon {
          max-width: 170px;
          flex: 0 0 100%;
          @include portrait-tablet {
            max-width: 30px;
            flex: 0 0 auto;
            display: block;
          }
        }

        &-text {
          padding-top: 10px;
          flex: 0 0 100%;
          @include portrait-tablet {
            padding-top: 0;
            margin-left: 10px;
            flex: 0 0 auto;
            display: block;
            max-width: 115px;
          }
        }

        & .img {
          object-fit: contain;
        }
      }

      &-right {
        z-index: 1100;
        @include portrait-tablet {
          padding-left: 0;
          padding-right: 0;
          flex: 0 0 100%;
        }
      }

      &-menu {
        padding-top: 20px;
        @include portrait-tablet {
          padding: 0;
          flex: 0 0 100%;
        }
      }
    }
  }

  &.menu-fixed {
    position: fixed;
    background-color: $color-yellow;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);

    &.home {
      padding-bottom: 0;
      @include tablet {
        max-width: 100%;
      }

      .header {

        &-wrapper {
          &:after {
            display: none;
          }
        }

        &-row {
          justify-content: space-between;
          @include tablet {
            height: 70px;
            align-items: flex-start;
          }
        }

        &-lang {
          @include tablet {
            padding-left: 0;
            padding-right: 0;
            height: auto;
            order: 1;
            position: absolute;
            right: 0;
            top: 0;
            padding-top: 10px;
            border-right: 0 none;
          }

          &:hover {
            .header-lang-modal {
              display: block;
            }
          }

          &-active {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;
            @include tablet {
              border: 1px solid rgba($color-white, 0.1);
              height: 48px;
              width: 48px;
              justify-content: center;
              line-height: 1;

            }

            &:after {
              @include tablet {
                display: none;
              }
            }
          }

          &-btn {

          }

          &-modal {
            position: absolute;
            left: 0;
            top: 25px;
            width: 100%;
            max-width: 47px;
            background-color: $color-white;
            display: none;
            @include tablet {
              top: 65px;
            }
          }

          &-btn {
            color: $color-blue-1;
            padding: 10px;
            border: 0 none;
            font-size: 1.4rem;
            text-transform: uppercase;
            height: 36px;
            @include tablet-hover {
              &:hover {
                background-color: rgba($color-blue-1, 0.15);
              }
            }

            &.active {
              color: $color-yellow;
            }
          }
        }

        &-phones {

          &:hover {
            .header-phones-modal {
              display: block;
            }
          }

          &-active {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            font-family: $font-osw;
            font-weight: $font-osw-bold;
            font-size: 1.6rem;

            &:after {
              content: '';
              position: relative;
              background-image: url("/images/svg/arrow-bot.svg");
              background-repeat: no-repeat;
              background-attachment: scroll;
              background-position: center;
              width: 8px;
              height: 4px;
              display: block;
              margin-left: 5px;
              transition: all 0.3s linear;
            }
          }

          &-modal {
            position: absolute;
            left: 0;
            top: 25px;
            width: 233px;
            background-color: $color-white;
            display: none;
            @include desktop-little {
              left: auto;
              right: 0;
            }
            @include tablet {
              right: -50%;
            }
          }

          &-block {
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
          }

          &-item {
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 10px;

            &.center {
              text-align: center;
            }

            &:last-of-type {
              padding-bottom: 0;
            }
          }

          &-phone {
            color: $color-blue-1;
            font-family: $font-osw;
            font-weight: $font-osw-bold;
            font-size: 1.6rem;
            line-height: 24px;
          }

          &-time {
            border-top: 1px solid rgba($color-blue-1, 0.15);
            padding: 20px;
            color: $color-blue-1;
            font-weight: $font-bold;
            display: flex;
            justify-content: center;
          }
        }

        &-logo {
          display: block;
          padding-top: 10px;
          flex: 0 0 124px;
          max-width: 124px;
          @include tablet {
            display: none;
          }

          &-link {
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            max-width: 94px;
            @include tablet {
              flex-wrap: nowrap;
            }

          }

          &-icon {
            //max-width: 170px;
            max-width: 76px;
            flex: 0 0 100%;
            @include tablet {
              max-width: 30px;
              flex: 0 0 auto;
              display: block;
            }
          }

          &-text {
            padding-top: 0;
            flex: 0 0 100%;
            margin-left: 0;
            @include tablet {
              padding-top: 0;
              margin-left: 10px;
              flex: 0 0 auto;
              display: block;
              max-width: 115px;
            }
          }

          & .img {
            object-fit: contain;
          }
        }

        &-menu {
          order: 2;
          flex: 1;
          padding-top: 0;
          padding-left: 40px;
          @include desktop-block {
            padding-left: 35px;
          }
          @include menu-tablet {
            padding-left: 0;
            padding-right: 0;
          }
          @include tablet {
            padding-top: 0;
          }

          &-before {
            display: none;
          }
        }

        &-right {
          order: 3;
          flex: 1;
          max-width: 360px;
          //padding: 10px 5px 0 0;
          padding: 0 5px 0 0;
          @include desktop-block {
            max-width: 340px;
          }
          @include middle-tablet {
            max-width: 240px;
          }
          @include tablet {
            z-index: 1100;
            padding-left: 0;
            padding-right: 0;
            //padding-top: 0;
            padding-top: 10px;
            justify-content: center;
            flex: 0 0 100%;
            max-width: 100%;
            display: flex;
            height: 58px;
          }
        }
      }
    }

    &.not-home {
      & .header {
        &-row {
          justify-content: space-between;
          align-items: center;
          @include tablet {
            justify-content: center;
          }
        }

        &-lang {
          @include tablet {
            padding-left: 0;
            padding-right: 0;
            height: auto;
            order: 1;
            position: absolute;
            right: 0;
            top: 0;
            padding-top: 10px;
            border-right: 0 none;
          }

          &:hover {
            .header-lang-modal {
              display: block;
            }
          }

          &-active {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            @include tablet {
              border: 1px solid rgba($color-white, 0.1);
              height: 48px;
              width: 48px;
              justify-content: center;
              line-height: 1;
            }

            &:after {
              @include tablet {
                display: none;
              }
            }
          }

          &-modal {
            position: absolute;
            left: 0;
            top: 25px;
            width: 100%;
            max-width: 47px;
            background-color: $color-white;
            display: none;
            @include tablet {
              top: 65px;
            }
          }

          &-btn {
            color: $color-blue-1;
            padding: 10px;
            border: 0 none;
            font-size: 1.4rem;
            text-transform: uppercase;
            height: 36px;
            @include tablet-hover {
              &:hover {
                background-color: rgba($color-blue-1, 0.15);
              }
            }

            &.active {
              color: $color-yellow;
            }
          }
        }

        &-phones {
          position: relative;
          height: 30px;
          align-items: center;
          display: flex;
          padding-left: 10px;
          padding-right: 10px;
          @include tablet {
            display: none;

          }

          &:hover {
            .header-phones-modal {
              display: block;
            }
          }

          &-active {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            font-family: $font-osw;
            font-weight: $font-osw-bold;
            font-size: 1.6rem;

            &:after {
              content: '';
              position: relative;
              background-image: url('/images/svg/arrow-bot.svg');
              background-repeat: no-repeat;
              background-attachment: scroll;
              background-position: center;
              width: 8px;
              height: 4px;
              display: block;
              margin-left: 5px;
              transition: all 0.3s linear;
            }
          }

          &-modal {
            position: absolute;
            left: 0;
            top: 25px;
            width: 233px;
            background-color: $color-white;
            display: none;
            @include desktop-little {
              left: auto;
              right: 0;
            }
          }

          &-block {
            padding: 20px;
            display: flex;
            flex-wrap: wrap;
            @include tablet {
              display: none;
            }
          }

          &-item {
            flex: 0 0 100%;
            max-width: 100%;
            padding-bottom: 10px;

            &.center {
              text-align: center;
            }

            &:last-of-type {
              padding-bottom: 0;
            }
          }

          &-phone {
            color: $color-blue-1;
            font-family: $font-osw;
            font-weight: $font-osw-bold;
            font-size: 1.6rem;
            line-height: 24px;
          }

          &-time {
            border-top: 1px solid rgba($color-blue-1, 0.15);
            padding: 20px;
            color: $color-blue-1;
            font-weight: $font-bold;
            display: flex;
            justify-content: center;
          }
        }

        &-logo {
          display: block;
          padding-top: 10px;
          flex: 0 0 124px;
          max-width: 124px;
          @include tablet {
            padding-top: 10px;
            justify-content: center;
            display: flex;
            flex: 0 0 100%;
            height: 58px;
            z-index: 1100;
            max-width: 100%;
          }

          &-link {
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            max-width: 94px;
            @include tablet {
              flex-wrap: nowrap;
            }

          }

          &-icon {
            //max-width: 170px;
            max-width: 76px;
            flex: 0 0 100%;
            @include tablet {
              max-width: 30px;
              flex: 0 0 auto;
              display: block;
            }
          }

          &-text {
            padding-top: 0;
            flex: 0 0 100%;
            @include tablet {
              padding-top: 0;
              margin-left: 10px;
              flex: 0 0 auto;
              display: block;
              max-width: 115px;
            }
          }

          & .img {
            object-fit: contain;
          }
        }

        &-menu {
          order: 2;
          flex: 1;
          padding-left: 40px;
          padding-top: 0;
          @include menu-tablet {
            padding-left: 0;
            padding-right: 0;
          }

          &-before {
            display: none;
          }
        }

        &-right {
          order: 3;
          flex: 1;
          max-width: 360px;
          //padding: 10px 5px 0 0;
          padding: 0 5px 0 0;
          @include desktop-block {
            max-width: 340px;
          }
          @include middle-tablet {
            max-width: 240px;
          }
        }
      }
    }

    & .header {

      &-site-width {
        @include tablet {
          padding-left: 34px;
          padding-right: 34px;
        }
        @include phone {
          padding-left: 12px;
          padding-right: 12px;
        }
      }

      &-burger {
        @include tablet {
          display: block;
        }
      }

      &-row {
        height: 70px;
        align-items: center;
      }

      &-logo {
        @include tablet {
          display: none;
        }
      }

      &-social {
        @include middle-tablet {
          display: none;
        }
      }

      &-menu {
        @include portrait-tablet {
          padding-top: 0;
        }

        &-block {
          @include tablet {
            display: none;
          }

        }

        &-before {
          position: relative;
          padding-right: 11px;

          span {
            z-index: 10;
            position: relative;
            color: $color-white;
            font-family: $font-osw;
            font-weight: $font-osw-bold;
            font-size: 1.4rem;
            height: 30px;
            display: flex;
            align-items: center;
          }

          &:before {
            z-index: 0;
            position: absolute;
            right: 0;
            top: 0;
            content: '';
            width: 500%;
            height: 30px;
            background-color: $color-yellow;
            border-radius: 0 11.5px 11.5px 0;
          }

          @include portrait-tablet {
            display: none;
          }
        }

        &-nav {
          @include tablet {
            padding: 70px 52px 70px;
            overflow-y: auto;
            height: 100%;
          }

          & .nav {
            &-ul {
              @include tablet {
                display: block;
                max-width: 350px;
              }

              &-sub {
                @include tablet {
                  transition: none;
                  width: 100%;
                  position: relative;
                  background-color: transparent;
                  margin-top: 0;
                  visibility: visible;
                  opacity: 1;
                  height: 100%;
                  display: none;
                }
              }
            }

            &-li {
              padding-left: 15px;
              padding-right: 15px;

              &:first-of-type {
                padding-left: 0;
              }

              @include middle-tablet {
                padding-right: 10px;
              }
              //@include tablet {
              //  padding: 30px 0 0;
              //  &:first-of-type {
              //    padding-top: 0;
              //  }
              //}

              &-sub {
                padding-left: 0;
                padding-right: 0;
              }
            }

            &-link {
              position: relative;
              font-family: $font-osw;
              font-weight: $font-osw-medium;
              line-height: 27px;
              z-index: 5;
              transition: none;
              font-size: 1.6rem;
              @include desktop-block{
                font-size: 1.4rem;
              }
              //@include tablet {
              //  font-size: 2.4rem;
              //}
              &:before {
                content: '';
                height: 1px;
                width: 0;
                position: absolute;
                left: 0;
                bottom: -3px;
                background-color: $color-white;
                transition: all 0.2s linear;
                @include portrait-tablet {
                  display: none;
                }
              }

              &.nav-link-arrow {
                display: flex;
                align-items: center;

                &.open {
                  color: $color-yellow;
                }

                &:after {
                  content: '';
                  height: 9px;
                  width: 10px;
                  margin-left: 7px;
                  background-image: url("/images/svg/arrow-bot.svg");
                  background-repeat: no-repeat;
                  background-attachment: scroll;
                  background-size: contain;
                  background-position: center center;
                  transform: rotate(0);
                  transition: all 0.3s linear;
                }
                  &:hover {
                    &:after {
                      transform: rotate(180deg);
                    }
                  }

                &.open {
                  color: $color-yellow;
                  &:after {
                    transform: rotate(180deg);
                    background-image: url("/images/svg/arrow-bot-hover.svg");
                  }
                }
              }

              @include tablet-hover {
                &:hover {
                  color: $color-white;
                  &:before {
                    content: '';
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: -3px;
                    background-color: $color-white;
                    @include portrait-tablet {
                      display: none;
                    }
                  }
                }
              }

              &-sub {
                font-weight: $font-regular;
                font-size: 1.4rem;
                font-family: $font-roboto;
                padding: 10px 20px;
                display: block;

                &:hover {
                  background-color: $color-blue-6;
                }

                &.active {
                  color: $color-yellow;
                  font-weight: $font-bold;
                }

                &:before {
                  display: none;
                }
              }
            }

          }
        }
      }
    }
  }

}

body.open-menu {

  @include tablet {
    overflow: hidden;
  }


}
