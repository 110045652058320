.btn {
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  color: $color-white;
  text-align: center;
  vertical-align: middle;
  font-size: 1.7rem;
  font-weight: $font-bold;
  background-color: transparent;
  border: 1px solid $color-white;
  max-width: 290px;
  width: 100%;
  text-transform: uppercase;
  height: 50px;

  &:focus {
    text-decoration: none;
  }

  &-white {
    background-color: transparent;
    border: 1px solid $color-white;
    color: $color-white;
    &:hover {
      background-color: $color-white;
      color: $color-green;
    }
  }

  &-green {
    background-color: transparent;
    border: 1px solid $color-green;
    color: $color-green;

    &:hover {
      background-color: $color-green;
      color: $color-white;
    }
  }

  &-border {
    border: 1px solid $color-yellow;
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      border-color: $color-yellow;
      box-sizing: border-box;
      border-style: solid;
      width: 1em;
      height: 1em;
      transition: all 0.3s ease-in-out;
    }

    &:before {
      left: -6px;
      bottom: -6px;
      border-width: 0 0 1px 1px;
      z-index: 5;
      width: 20%;
    }

    &:after {
      top: -6px;
      right: -6px;
      border-width: 1px 1px 0 0;
      width: 50%;
    }

    &:hover:before, &:hover:after {
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border-color: $color-yellow-1;
    }

    &:hover {
      color: $color-white;
      background-color: $color-yellow-1;
      border-color: $color-yellow-1;;
    }
  }

  &-yellow {
    border: 1px solid $color-yellow;
    position: relative;
    background-color: $color-yellow;
    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      border-color: $color-yellow;
      box-sizing: border-box;
      border-style: solid;
      width: 1em;
      height: 1em;
      transition: all 0.3s ease-in-out
    }

    &:before {
      left: -6px;
      bottom: -6px;
      border-width: 0 0 1px 1px;
      z-index: 5;
      width: 20%;
    }

    &:after {
      top: -6px;
      right: -6px;
      border-width: 1px 1px 0 0;
      width: 50%;
    }

    &:hover:before, &:hover:after {
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      border-color: $color-yellow-1;
    }

    &:hover {
      color: $color-white;
      background-color: $color-yellow-1;
      border-color: $color-yellow-1;
    }
  }
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
}
