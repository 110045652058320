.p-news{

}
.news{
  padding-top: 30px;
  &-wrapper{}
  &-row{
    @include phone{
      justify-content: center;
    }
  }
  .posts {
    &-col {
      padding-bottom: 30px;

    }
  }
  nav{
    padding-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    .pagination{
      display: flex;
    }
    .page-item{
      .page-link{
        font-size: 1.4rem;
        color: $color-white;
        line-height: 1;
        padding: 5px;
        border-radius: 2px;
        min-width: 24px;
        display: flex;
        justify-content: center;
      }
      &:hover{
        .page-link{
          background-color: $color-yellow;
        }
      }
      &.active{
          .page-link{
          background-color: $color-blue-5;
        }
      }
      &.disabled{
        .page-link{
          opacity: 0.2;
        }
        &:hover{
          .page-link{
            background-color: transparent;
          }
        }

      }
    }
  }
}
