.p-post{

}
.posts {
  padding-top: 100px;
  &-title{
    &--title {
      text-transform: uppercase;
      font-size: 1.6rem;
      line-height: 19px;
    }
  }
  &-col{
    flex: 0 0 33.33%;
    max-width: 380px;
    padding-bottom: 30px;
    @include portrait-tablet{
      flex: 0 0 50%;
    }
    @include phone{
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;


    }
    &:last-of-type{
      @include phone{
        padding-bottom: 0;
      }
    }
  }
  &-row{
    padding-top: 20px;
    @include phone{
      justify-content: center;
    }
  }
  &-item{
    border: 1px solid rgba($color-gray-5,0.2);
    position: relative;
    transition: all 0.3s linear;
    &:before{
      content: '';
      display: block;
      position: absolute;
      background-color: $color-yellow;
      box-sizing: border-box;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      transition: all 0.3s ease-in-out
    }
    &-img{
      height: pxVh(200,1080);
      & .img{
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    &-desc{
      padding: 20px;
      min-height: 115px;
    }
    &-title{
      min-height: 42px;
      &--title{
        color: $color-white;
        font-weight: $font-bold;
        font-size: 1.6rem;
        line-height: 20.8px;
      }
    }
    &-info{
      padding-top: 14px;
      display: flex;
      justify-content: space-between;
    }
    &-date{
      &--item{
        color: $color-white;
        font-weight: $font-osw-medium;
        font-family: $font-osw;
        opacity: 0.5;
        font-size: 1.4rem;
      }
    }
    &-link{
      &--link{
        font-family: $font-osw;
        color: $color-yellow;
        font-weight: $font-osw-medium;
        text-transform: uppercase;
      }
    }

  }
}
.post{
  padding-top: 50px;
  &-wrapper{
    max-width: 760px;
  }
  &-date{
    &--desc{
      font-weight: $font-osw-reg;
      font-family: $font-osw;
      font-size: 1.4rem;
    }
  }
  &-title{
    padding-top: 10px;
    &--title{
      font-size: 3rem;
      line-height: 35px;
    }
  }
  &-banner{
    padding-top: 30px;
    &-img{
      height: pxVh(410,1080);
      overflow: hidden;
      .img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-content{
    padding-top: 30px;
  }
}

