.p-price{

}
.price{
  padding-top: 30px;
  &-wrapper{}
  &-list{
    &:not(.open){
      .price-head-title{
        &:before{
          transform: rotate(0);
          transition: transform 0.3s linear;
        }
      }
    }
  }
  &-head{
    border-top: 1px solid rgba($color-white,0.05);
    border-bottom: 1px solid rgba($color-white,0.05);
    cursor: pointer;
    &-title{
      padding-top: 12px;
      padding-bottom: 12px;
      position: relative;
      display: inline-flex;
      width: 100%;
      padding-left: 5px;
      color: $color-white;
      //transition: all 0.3s linear;
      &:before{
        content: '';
        position: relative;
        background-image:  url(/images/svg/arrow-price.svg);
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        background-size: contain;
        width: 6px;
        height: 10px;
        display: block;
        margin-right: 17px;
        transform: rotate(90deg);
        transition: all 0.3s linear;
      }
      &:hover{
        color: $color-yellow;
        &:before{
          background-image:  url(/images/svg/arrow-price-hov.svg);
        }
      }
    }

  }
  &-table{
    padding-left: 30px;
    font-size: 1.4rem;
    line-height: 30px;
    display: none;
    @include phone{
      padding-left: 0;
      font-size: 1.2rem;
      line-height: 15px;
    }
    &-head{
      color: $color-blue;
      display: flex;
      height: 36px;
      align-items: center;
      & .price-table-text{
        padding-left: 0;
      }

    }
    &-row{
      display: flex;
      @include phone{
        padding: 15px 0;
      }
      &:nth-of-type(2n+1){
        background-color: rgba($color-white,0.05);
      }
    }
    &-col{
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      display: flex;
      align-items: center;
    }
    &-text{
      padding-left: 20px;
      padding-right: 20px;
      max-width: 675px;
      flex: 0 0 60%;
      @include phone{
        padding-left: 15px;
        padding-right: 5px;
        flex: 0 0 50%;
      }
    }
    &-terms{
      @include phone{
        flex: 0 0 25%;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    &-price{
      //max-width: 150px;
      max-width: 240px;
      padding-right: 20px;
      justify-content: flex-end;
      text-align: right;
      @include phone{
        padding-left: 5px;
        padding-right: 15px;
        flex: 0 0 25%;
      }
    }
  }
}
