

*, :after, :before {
  box-sizing: border-box;
}

*:active, *:hover, *:focus {
  outline: 0;
  outline-offset: 0;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 10px;
  color: $main-color;
  line-height: 180%;
  min-width: 320px;
}

body {
  margin: 0;
  min-width: 320px;
  //min-width: 375px;
  overflow-y: scroll;
  font-family: $font-roboto;
  font-size: 1.4rem;
  color: $color-white;
  min-height: 100%;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  background:$gradient-blue;
}


img {
  max-width: 100%;
  height: auto;
}

h1{
  font-family: $font-osw;
  font-weight: $font-osw-bold;
  font-size: 3.6rem;
  line-height: 1.1;
  color: $color-white;
  text-transform: uppercase;
}
h2 {
  font-family: $font-osw;
  font-weight: $font-osw-bold;
  color: $color-white;
  font-size: 2.5rem;
}

h3 {
  font-family: $font-osw;
  font-weight: $font-osw-bold;
  color: $color-white;
  font-size: 1.8rem;
}

a {
  transition: all 0.3s linear;
}
p{
  font-size: 1.4rem;
  padding-top: 20px;
  line-height: 25.3px;
  color: $color-white;
  &:first-of-type{
    padding-top: 0;
  }
}

.list {
  padding: 20px 0;
  &-link {
    display: flex;
    border: 1px solid $color-blue;
    border-radius: 30px;
    padding: 20px;
    font-size: 2rem;
    color: $color-blue;
    justify-content: center;
    &:hover{
      background-color: $color-blue;
      color: $color-white;
    }
  }
}


.title-block{
    text-align: center;
    .title{
        font-family: $font-osw;
        font-weight: $font-osw-bold;
        font-size: 3.6rem;
        line-height: 110%;
        color: $color-white;
    }
}
.site {
  overflow: hidden;
  position: relative;
}
.p-page{
  min-height: 400px;
  padding-top: 220px;
  @include portrait-tablet{
    min-height: 600px;
    padding-top: 70px;
  }
}
.top-block{
  padding-top: 30px;
  .title-block{
    padding-top: 50px;
    text-align: left;
  }
}
.breadcrumbs{
  ul{
    display: flex;
  }
  li{
    padding-right: 25px;
    position: relative;
    &:after{
      position: absolute;
      right: 10px;
      top: 1px;
      content: '';
      display: block;
      background-image:  url(/images/svg/arrow-bread.svg);
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-position: center;
      //background-size: contain;
      width: 5px;
      height: 10px;
    }
    &:last-of-type{
      &:after{
        display: none;
      }
    }
  }
  a{
    font-size: 1.4rem;
    color: $color-white;
    &:hover{
      color: $color-yellow;
    }
  }
  span{
    font-size: 1.4rem;
    color: $color-yellow;
  }
}
