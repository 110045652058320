.p-service{
  .title-block{
    //padding-top: 50px;
  }
}

.p-services{
  .title-block{
    //padding-top: 50px;
  }
}

.services{
  padding-top: 30px;
  &-wrapper{

  }
  &-row{
    @include portrait-tablet{
      justify-content: center;
    }
  }
  &-col{
    flex: 0 0 33.33%;
    max-width: 380px;
    padding-bottom: 30px;
    @include portrait-tablet{
      flex: 0 0 50%;
    }
    @include phone{
      flex: 0 0 100%;
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
    }
  }
  &-item{
    //height: 208px;
    padding: 20px;
    border: 1px solid rgba($color-gray-5,0.2);
    position: relative;
    transition: all 0.3s linear;
    min-height: 208px;
    &:before{
      content: '';
      display: block;
      position: absolute;
      background-color: $color-yellow;
      box-sizing: border-box;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      transition: all 0.3s ease-in-out
    }
    &-title{
      font-weight: 700;
      font-size: 2rem;
      line-height: 26px;
      color: $color-white;
    }
    &-desc{
      padding-top: 10px;
      //min-height: 106px;

      &>p{
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 18px;
        color: rgba($color-white,0.7);
        display: none;
        //&.preview{
        //  display: block;
        //}
        &:first-of-type{
          display: block;
        }
      }
    }
    &>ol,&>ul,&>span{
      display: none;
    }
    &-link{
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      width: 100%;
      left: 0;
      &--link{
        color: rgba($color-yellow,1);
        font-family: $font-osw;
        font-weight: $font-osw-medium;
        //padding-right: 10px;
        display: inline-flex;
        font-size: 1.4rem;
        text-transform: uppercase;
        &:after{
          margin-left: 10px;
          content: '';
          position: relative;
          background-image:  url("/images/svg/arrow-yellow.svg");
          background-repeat: no-repeat;
          background-attachment: scroll;
          background-position: center;
          background-size: contain;
          width: 14px;
          height: 100%;
          display: block;
          transition: all 0.3s linear;
        }
      }
    }
    &:hover{
      background-color: $color-yellow;
      .services-item-link--link{
        color: $color-white;
        &:after {
          background-image: url("/images/svg/arrow-white.svg");
        }
      }
    }
  }

}

.service{
  padding-top: 50px;
  &-wrapper{

  }
  &-row{
    @include portrait-tablet{
      flex-wrap: wrap;
    }
  }
  &-sidebar{
    max-width: 380px;
    @include desktop-block{
      max-width: 320px;
    }
    @include tablet{
      max-width: 240px;
    }
    @include portrait-tablet{
      max-width: 100%;
      flex: 0 0 100%;
    }
    &-item{

    }
    &-title{
      &---title{

      }
    }
    &-list{
      padding-top: 20px;
    }
    &-ul{

    }
    &-li{
      //padding: 10px 20px;
      font-size: 1.4rem;
      line-height: 28px;
      background-color: rgba($color-white,0.2);
      position: relative;
      margin-bottom: 2px;
      &:hover,&.active{
        color: $color-yellow;
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        background-color: $color-yellow;
        box-sizing: border-box;
        left: 0;
        top: 0;
        width: 1px;
        height: 100%;
        transition: all 0.3s ease-in-out
      }
    }
    &-link{
      padding: 10px 20px;
      display: block;
    }
    &-buttons{
      padding-top: 30px;
      @include portrait-tablet{
        display: none;
      }
      & .btn-border-norm{
        max-width: 100%;
        font-size: 1.2rem;
        color: $color-yellow;
        border: 1px solid $color-yellow;

      }

    }
  }
  &-content{
    @include portrait-tablet{
      max-width: 100%;
      flex: 0 0 100%;
      padding-top: 30px;
    }
    &-item{

    }
    &-title{
      &--tile{

      }
    }
    &-desc{
      padding-top: 30px;
      p{

      }
      ul,ol{
        list-style: initial;
        list-style-position: inside;
        line-height: 1.3;
        li{
          padding-top: 15px;
        }
      }
    }
    &-buttons{
      padding-top: 30px;
      display: none;
      @include portrait-tablet{
        display: block;
      }
      & .btn-border-norm{
        max-width: 100%;
        font-size: 1.2rem;
        color: $color-yellow;
        border: 1px solid $color-yellow;

      }

    }
  }
}

.guarantee{
  padding-top: 150px;
  @include desktop-block{
    padding-top: 100px;
  }
  @include portrait-tablet{
    padding-top: 70px;
  }
  &-wrapper{

  }

  &-title{
    &--tile{

    }
  }
  &-row{
    padding-top: 50px;
    @include portrait-tablet{
      flex-wrap: wrap;
      padding-top: 30px;
    }
  }
  &-col{
    @include portrait-tablet{
      flex: 0 0 100%;
      display: flex;
      justify-content: center;
    }
    &:last-of-type{
      @include portrait-tablet{
        padding-top: 50px;
      }
      //.guarantee-title--title{
      //  &:before{
      //    background-image:  url("/images/svg/guarantee-low.svg");
      //  }
      //}
    }
  }
  &-item{

  }
  &-title{
    display: flex;
    &-img{
      width: 64px;
      height: 64px;
      .img{
        width: 100%;
      }
    }
    &--title{
      padding-left: 30px;
      font-family: $font-osw;
      font-weight: $font-osw-bold;
      font-size: 1.8rem;
      line-height: 27px;
      color: $color-yellow;
      position: relative;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      //&:before{
      //  margin-right: 20px;
      //  content: '';
      //  position: relative;
      //  left: 0;
      //  top: 0;
      //  background-repeat: no-repeat;
      //  background-attachment: scroll;
      //  background-position: center;
      //  background-size: contain;
      //  width: 64px;
      //  height: 64px;
      //  display: block;
      //  background-image:  url("/images/svg/guarantee-contract.svg");
      //}
    }
  }
  &-list{
    padding-top: 30px;
    max-width: 420px;
    &-desc{
      color: $color-white;
      font-size: 1.4rem;
      line-height: 18px;
    }
    &-ul{

    }
    &-li{
      color: $color-white;
      font-size: 1.4rem;
      line-height: 18px;
      padding-bottom: 20px;
      position: relative;
      padding-left: 18px;
      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-image:  url("/images/svg/arrow-list.svg");
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center;
        background-size: contain;
        width: 6px;
        height: 10px;
        display: block;
      }
      &:last-of-type{
        padding-bottom: 0;
      }
    }
  }
}

.gallery{
  padding-top: 70px;
  &-wrapper{

  }
  &-block{
    padding-top: 50px;
  }
  &-slider{
    display: flex;
    padding-left: 60px;
    padding-right: 60px;
    @include desktop-block{
      padding-left: 52px;
      padding-right: 52px;
    }
    @include tablet{
      padding-left: 42px;
      padding-right: 42px;
    }
    @include phone{
      padding-left: 0;
      padding-right: 0;
    }
    .slick-arrow.slick-prev{
      left: 0;
      width: 20px;

      &:before{
        left: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        background-size: contain;
      }
    }
    .slick-arrow.slick-next{
      width: 20px;
      right: 0;
      &:before{
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        background-size: contain;
      }
    }
  }
  & .slick-list{
    padding: 0;
  }
  & .slick-slide{
    //width: 33.33%;
    max-width: 330px;
    flex: 0 0 33.33%;
    padding-left: 15px;
    padding-right: 15px;
    @include tablet{
      padding-left: 5px;
      padding-right: 5px;
    }

  }
  &-slide{
    flex: 0 0 33%;
    max-width: 330px;
    width: 100%;
    display: flex;
    //padding: 0 15px;
  }
  &-item{
    width: 100%;
  }
  &-img{
    width: 100%;
    height: 262px;
    overflow: hidden;
    background-color: rgba($color-gray-1,0.5);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;

    //.img{
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //}
  }
}
