.consultation {
  //padding-top: 150px;
  padding-top: 100px;
  @include phone{
    padding-top: 70px;
  }
  .title-block{
    @include tablet{
      max-width: 340px;
      margin: 0 auto;
    }
    .title{
      font-size: 3rem;
      line-height: 33px;
      @include tablet{
        font-size: 2.4rem;
      }
    }
  }

  &-container {

  }


  &-wrapper {
    position: relative;
    z-index: 10;
  }

  &-block {

  }

  &-slide {

  }

  &-row {
    padding-top: 53px;
    @include tablet{
      padding-top: 30px;
    }
  }

  &-col {
    display: flex;
    align-items: center;
    @include tablet{
      padding: 0;
    }
  }

  &-item {
    color: $color-white;
    max-width: 365px;
  }

  &-title {
    &--title {
      font-family: $font-osw;
      font-weight: $font-osw-bold;
      font-size: 3.6rem;
      line-height: 53px;
    }
  }

  &-desc {
    padding-top: 20px;
    font-size: 1.4rem;
    line-height: 180%;
  }

  &-btn {
    padding-top: 76px;

    .btn {

    }
  }

  &-img {
    &-item {

    }
  }


  .form {
    width: 100%;
    &-block {
      &-row {
        display: flex;
      }
    }
    &-col{
      flex: 0 0 33.33%;
      max-width: 380px;
      @include tablet{
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &-item{

    }
    .btn{
      max-width: 380px;
    }
  }
}
